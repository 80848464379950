import React from 'react';
import { Box, Typography } from '@mui/material'; // Import Box and Typography from Material-UI
import './GameHomePageComponents.scss'; // Import the SCSS file

const HomePageEntryAndPrizes = ({ prizes, hasJoinDeadlinePassed }) => {
    //console.log("HomePagePrizes", prizes, hasJoinDeadlinePassed);
  return (
    <Box className="home-page-prizes-container"> {/* Added className to Box */}
      <Typography 
          variant="body1" 
          className="home-page-prizes-header"
      >
        Pool Entry
      </Typography>
      <Typography 
        variant="body1" 
        className="home-page-prizes-text"
      >
        {!hasJoinDeadlinePassed ? (
          <span className="prize-text">
            <span className="highlight">FREE!</span>
          </span>
        ) : (
          <span className="prize-text">
            <span className="red">We're sorry, but entry to this pool is CLOSED.</span>
          </span>
        )}
      </Typography>


      <Typography 
        variant="body1" 
        className="home-page-prizes-header"
      >
        Pool Prizes
      </Typography>

    {prizes?.firstPlace && (
      <Typography 
        variant="body1" 
        className="home-page-prizes-text"
      >
        <span className="prize-text">
          <span className="highlight">1st Place</span> - {prizes?.firstPlace}
        </span>
      </Typography>
    )}
    {prizes?.secondPlace && (
      <Typography 
        variant="body1" 
        className="home-page-prizes-text"
      >
        <span className="prize-text">
          <span className="highlight">2nd Place</span> - {prizes?.secondPlace}
        </span>
      </Typography>
    )}
    {prizes?.thirdPlace && (
      <Typography 
        variant="body1" 
        className="home-page-prizes-text"
      >
        <span className="prize-text">
          <span className="highlight">3rd Place</span> - {prizes?.thirdPlace}
        </span>
      </Typography>
    )}
    {prizes?.lastPlace && (
      <Typography 
        variant="body1" 
        className="home-page-prizes-text"
      >
        <span className="prize-text">
          <span className="highlight">Last Place</span> - {prizes?.lastPlace}
        </span>
      </Typography>
    )}
    </Box>
  );
}

export default HomePageEntryAndPrizes;
