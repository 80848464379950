import React, { useState, useEffect } from 'react';
import { Paper, Box, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Typography } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../../NFLPickEm/NFLPickEmStyles/PickEmLeaderboardStyles.scss';
import TableDropdown from '../../TableDropdown/TableDropdown';
import PercentagePillBox from '../../PercentagePillBox/PercentagePillBox';
import TeamStatsConferenceSelection from '../DropdownMenus/TeamStatsConferenceSelection';
import { useCFBSurvivorContext } from '../context/CFBSurvivorContext';
import { DateTime } from 'luxon';
import CloseIcon from '@mui/icons-material/Close';
import '../../NFLSurvivor/NFLSurvivorStyles/SurvivorPickStats.scss';


const CFBSurvivorTeamStats = ({ handleClickState, leaderboardData, currentWeeksDeadlineDateTime }) => {

    //console.log("Leaderboard Data in Team Stats:", leaderboardData);


  const { 
    startingWeekState, 
    CFBTeams, 
    CFBNames,
    CFBGamesByWeek,
    partyState,
    weekOptions,
    teamFilter,
    isSurvivorOver,
 } = useCFBSurvivorContext();

  const [sortBy, setSortBy] = useState('timesPicked');
  const [sortOrder, setSortOrder] = useState('desc');
  const [seasonVsWeek, setSeasonVsWeek] = useState(`Week ${startingWeekState}`);
  const [conferenceSelection, setConferenceSelection] = useState(teamFilter);
  //console.log("Season vs Week TEAM STATS", seasonVsWeek);

  useEffect(() => {
    setSortBy('timesPicked');
    setSortOrder('desc');
  }, [seasonVsWeek, partyState]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const teamLogo = (teamId) => {
    return CFBTeams?.find(team => team.GlobalTeamID === teamId)?.TeamLogoUrl;
  };

  const teamKey = (teamId) => {
    return CFBNames?.find(team => team.GlobalTeamID === teamId)?.ShortDisplayName;
  };

  const teamName = (teamId) => {
    return CFBTeams?.find(team => team.GlobalTeamID === teamId)?.Name;
  };

  const cfbOpponentInfo = (gameId, teamId) => {
    const game = CFBGamesByWeek?.find(game => game.GlobalGameID === gameId);
    const isPickHome = game?.GlobalHomeTeamID === teamId;
    const opponentKey = isPickHome ? teamKey(game?.GlobalAwayTeamID) : teamKey(game?.GlobalHomeTeamID);
    const leadingSymbol = isPickHome ? 'vs' : '@';
    const displayString = `${leadingSymbol} ${opponentKey}`;
    return displayString;
  };

  // Current time in New York timezone
  const timeInNYNow = DateTime.now().setZone('America/New_York');
    
  //const dummyDate = DateTime.fromISO('2024-09-10T12:00:00.000-05:00').setZone('America/New_York');
  //console.log("DUMMY DATE", dummyDate);
  

 

  function teamStatsData() {
    const teamStats = [];
    const allFilteredPicks = [];

    if (seasonVsWeek !== 'Season') {
        const selectedWeek = seasonVsWeek?.replace(/\D/g, '');
        for (let i = 0; i < leaderboardData?.length; i++) {
            const userPicks = leaderboardData[i].picks || [];
            //console.log("USER PICKS", userPicks, leaderboardData[i].username);
            const filteredPicks = userPicks?.filter(pick => {
                // Check if the current week's deadline has passed
                const shouldApplyTimeFilter = DateTime.fromISO(currentWeeksDeadlineDateTime) > timeInNYNow;
            
                return (
                    String(pick.week) === selectedWeek &&
                    pick.conference === conferenceSelection &&
                    (pick.teamId === 'No Pick' || !shouldApplyTimeFilter || DateTime.fromISO(pick.dateTime) < timeInNYNow)
                );
            });
            
            //console.log("FILTERED PICKS", filteredPicks);
            allFilteredPicks.push(...filteredPicks);
        }
    } else {
        for (let i = 0; i < leaderboardData?.length; i++) {
            const userPicks = leaderboardData[i].picks || [];
            const filteredPicks = userPicks?.filter(pick => String(pick.week) <= startingWeekState && pick.conference === conferenceSelection && DateTime.fromISO(pick.dateTime) < timeInNYNow);
            allFilteredPicks.push(...filteredPicks);
        }
    }

      teamStats.push({ filteredPicks: allFilteredPicks });
      return teamStats;
  }
  const teamStats = teamStatsData();
  //console.log("TEAM STATS", teamStats);

  function compileTeamStats(filteredPicks) {
    const teamStats = {};

    filteredPicks.forEach(pick => {
        const { gameId, teamId, spread, dateTime, result } = pick;
        const key = `${teamId}_${gameId}`; // Create a unique key based on teamId and gameId

        if (!teamStats[key]) {
            teamStats[key] = {
                gameId: gameId,
                teamId: teamId,
                timesSelected: 0,
                spreads: new Set(), // using Set to keep unique spreads
                dateTime: dateTime, // assuming dateTime is the same for all picks of the same team in the same game
                result: {
                    Win: 0,
                    Loss: 0,
                    Push: 0,
                    Pending: 0
                }
            };
        }

        // Increment the times selected
        teamStats[key].timesSelected += 1;

        // Add the spread to the set of spreads (Set ensures uniqueness)
        teamStats[key].spreads.add(spread);

        // Increment the result count
        if (result in teamStats[key].result) {
            teamStats[key].result[result] += 1;
        } else {
            teamStats[key].result.Pending += 1;
        }

        // Add win percentage to the teamStats object
        const totalGames = teamStats[key].result.Win + teamStats[key].result.Loss + teamStats[key].result.Push;
        const winPercentage = (teamStats[key].result.Win / totalGames) * 100;
        teamStats[key].winPercentage = isNaN(winPercentage) ? ' - ' : winPercentage.toFixed(2) + '%';

        // Add Team Key for sorting purposes
        const teamName = teamKey(teamId);
        teamStats[key].teamKey = teamName;

        // Calculate the Overall Result
        let overallResult;
        let poolRecordColor = '#1E1E1E';
        const { Win: winCount, Loss: lossCount, Push: pushCount, Pending: pendingCount } = teamStats[key].result;
        
        if (winCount > 0 && lossCount === 0 && pushCount === 0 && pendingCount === 0) {
            overallResult = "Win";
            poolRecordColor = '#00AA72';
        } else if (winCount === 0 && lossCount > 0 && pushCount === 0 && pendingCount === 0) {
            overallResult = "Loss";
            poolRecordColor = '#CC293C';
        } else if (winCount === 0 && lossCount === 0 && pushCount > 0 && pendingCount === 0) {
            overallResult = "Push";
        } else if (winCount === 0 && lossCount === 0 && pushCount === 0 && pendingCount > 0) {
            overallResult = "Pending";
        } else {
            overallResult = "Split";
        }
        teamStats[key].overallResult = overallResult;
        teamStats[key].poolRecordColor = poolRecordColor;
        
    });

    // Convert spreads Set back to an array for each team
    for (const key in teamStats) {
        teamStats[key].spreads = Array.from(teamStats[key].spreads);
    }

    return teamStats;
}


    // Assuming teamStatsData returns an array with a single object
    const teamStatsArray = teamStatsData();
    //console.log("TEAM STATS ARRAY", teamStatsArray);
    const filteredPicks = teamStatsArray[0]?.filteredPicks || [];
    const compiledTeamStats = compileTeamStats(filteredPicks);

    // Convert the compiledTeamStats object to an array
    const teamStatsArrayForSorting = Object.values(compiledTeamStats);

    
    // Sort the array by timesSelected
    const sortedTeamStats = teamStatsArrayForSorting?.sort((a, b) => b.timesSelected - a.timesSelected);

    //console.log("SORTED TEAM STATS", sortedTeamStats);

    const totalWeeklySelections = sortedTeamStats?.reduce((acc, team) => acc + team.timesSelected, 0);
    //console.log("TOTAL WEEKLY SELECTIONS", totalWeeklySelections);




  const handleSort = (columnName) => {
    if (sortBy === columnName) {
      // Toggle sorting order if the same column is clicked again
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // Sort by the clicked column
      setSortBy(columnName);
      if (columnName === 'timesPicked' || columnName === 'result' || columnName === 'winPercentage') {
        // For player name, always start with ascending order
        setSortOrder('desc');
      } else {
        // For other columns, use the current sort order
        setSortOrder('asc');
      }
    }
  };

  const sortedData = [...sortedTeamStats].sort((a, b) => {
    if (sortBy === 'timesPicked') {
      return sortOrder === 'asc' ? a.timesSelected - b.timesSelected : b.timesSelected - a.timesSelected;
    } else if (sortBy === 'teamKey') {
      // Handle undefined or empty teamKey
      const aKey = a.teamKey === undefined || a.teamKey === '' ? null : a.teamKey;
      const bKey = b.teamKey === undefined || b.teamKey === '' ? null : b.teamKey;

      // Ensure null values are sorted to the end
      if (aKey === null && bKey !== null) return sortOrder === 'asc' ? 1 : -1; // a is null
      if (bKey === null && aKey !== null) return sortOrder === 'asc' ? -1 : 1; // b is null

      return sortOrder === 'asc' ? aKey.localeCompare(bKey) : bKey.localeCompare(aKey);
    } else if (sortBy === 'result') {
      return sortOrder === 'asc' ? a.overallResult.localeCompare(b.overallResult) : b.overallResult.localeCompare(a.overallResult);
    } else if (sortBy === 'winPercentage') {
      const aWinPercentage = a.winPercentage === ' - ' ? -1 : parseFloat(a.winPercentage);
      const bWinPercentage = b.winPercentage === ' - ' ? -1 : parseFloat(b.winPercentage);
    
      return sortOrder === 'asc' ? aWinPercentage - bWinPercentage : bWinPercentage - aWinPercentage;
    }    
});

  

  const timesPickedColor = sortBy === 'timesPicked' ? '#FFF' : '#fff'; //FIX IF SORT IS FIXED!!!!!!!!!!!
  const teamKeyColor = sortBy === 'teamKey' ? '#FFC60A' : '#fff';
  const resultColor = sortBy === 'result' ? '#FFC60A' : '#fff';
  const winPercentageColor = sortBy === 'winPercentage' ? '#FFC60A' : '#fff';

  //console.log("IS MOBILE", isMobile, seasonVsWeek);

  const weekNumber = parseInt(seasonVsWeek.split(' ')[1], 10);

// This function determines the lossWeek property to use based on conference selection
function getLossWeekProperty(conference) {
    switch (conference) {
        case 'ACC':
            return 'lossWeekACC';
        case 'Big 12':
            return 'lossWeekBig12';
        case 'Big Ten':
            return 'lossWeekBigTen';
        case 'SEC':
            return 'lossWeekSEC';
        default:
            return 'lossWeek'; // Default to a general lossWeek if conference is not matched
    }
}

const lossWeekProperty = getLossWeekProperty(conferenceSelection);
//console.log("LOSS WEEK PROPERTY", lossWeekProperty);

const totalAlive = leaderboardData?.filter(user => user[lossWeekProperty] == null || user[lossWeekProperty] >= weekNumber).length;
const totalEliminated = leaderboardData?.filter(user => user[lossWeekProperty] === weekNumber).length;


const howManyLeftOnLeaderboard = leaderboardData?.filter(user => user.isUserEliminated === false)?.length;
//console.log("How Many Left On Leaderboard:", howManyLeftOnLeaderboard);

const selectedWeek = seasonVsWeek?.replace(/\D/g, '');

const lastWeekAPickWasMade = leaderboardData?.reduce((acc, user) => {
    // Filter out picks where teamId is "No Pick"
    const userPicks = (user?.picks || []).filter(pick => pick.teamId !== "No Pick" && pick.conference === conferenceSelection);
    //console.log("USER PICKS", userPicks);
    const lastPick = userPicks?.reduce((acc, pick) => {
        return pick.week > acc ? pick.week : acc;
    }, 0);
    
    return lastPick > acc ? lastPick : acc;
  }, 0);
  //console.log("Last Week A Pick Was Made:", lastWeekAPickWasMade);

  const isThisGroupOver = howManyLeftOnLeaderboard <= 1;
  let filteredWeekOptions = weekOptions;

  if (isSurvivorOver || isThisGroupOver) {
    filteredWeekOptions = weekOptions?.filter(option => {
        // Convert the key to a number before comparing
        const weekKey = Number(option?.key);
        return weekKey <= (lastWeekAPickWasMade === 0 ? 1 : lastWeekAPickWasMade);
    });
  }

//   useEffect(() => {
//     if (filteredWeekOptions?.length < startingWeekState && selectedWeek > filteredWeekOptions?.length) {
//         setSeasonVsWeek(`Week ${filteredWeekOptions?.[0]?.key}`);
//     }
//   }, [filteredWeekOptions, startingWeekState]);



  return (
    <Box>
        <TeamStatsConferenceSelection conferenceSelection={conferenceSelection} setConferenceSelection={setConferenceSelection}/>
        <TableDropdown seasonVsWeek={seasonVsWeek} setSeasonVsWeek={setSeasonVsWeek} weekOptions={weekOptions}/>
        
        <div className="survivor-entry-info-container">
            <div className="survivor-entry-info">
                <div className="status-section">
                    <Typography 
                        variant="h6" 
                        className="status-header">
                        Alive Entering Week
                    </Typography>
                    <Typography variant="body1" className="status-details">
                        {totalAlive}
                    </Typography>
                </div>
                <div className="status-section">
                    <Typography variant="h6" className="status-header">
                        Weekly Eliminations
                    </Typography>
                    <Typography variant="body1" className="status-details">
                        {totalEliminated}
                    </Typography>
                </div>
            </div>
        </div>

        <TableContainer component={Paper} style={{ overflowX: "clip", maxWidth:'930px', margin:'0 auto', marginBottom: '30px', borderRadius: '0' }}>
    <Table>
        <TableHead sx={{ backgroundColor: '#002129' }}>
            <TableRow>
                <TableCell
                    className="pickem-leaderboard-table-cells"
                    // onClick={() => handleSort('teamKey')}
                    sx={{ 
                        width: isMobile ? '176px' : '410px', 
                        color: teamKeyColor, 
                        fontWeight: 700, 
                        textAlign: 'center', 
                        position: 'relative', 
                        // cursor: 'pointer', 
                        fontSize: isMobile ? '14px' : '22px', 
                        // textDecoration: 'underline'
                    }}
                >
                    TEAM
                </TableCell>
                {!isMobile && (
                    <TableCell
                        className="pickem-leaderboard-table-cells"
                        // onClick={() => handleSort('timesPicked')}
                        sx={{ 
                            width: '254px', 
                            color: timesPickedColor, 
                            fontWeight: 700, 
                            textAlign: 'center', 
                            position: 'relative', 
                            // cursor: 'pointer', 
                            fontSize: isMobile ? '14px' : '22px'
                        }}
                    >
                        TIMES PICKED
                    </TableCell>
                )}
                <TableCell
                    className="pickem-leaderboard-table-cells"
                    // onClick={() => handleSort('winPercentage')}
                    sx={{ 
                        width: isMobile ? '199px' : '377px', 
                        color: winPercentageColor, 
                        fontWeight: 700, 
                        textAlign: 'center', 
                        position: 'relative', 
                        // cursor: 'pointer', 
                        fontSize: isMobile ? '14px' : '22px', 
                        // textDecoration: 'underline'
                    }}
                >
                    {isMobile ? 'SELECTED' : 'PCT'}
                </TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {sortedTeamStats?.map((team, index) => (
               <TableRow
               key={`${team.teamId}_${team.gameId}`}
               sx={{
                   backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F0F1F1',
               }}
           >
                    {isMobile && (
                        <TableCell>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>                                
                            {team.teamId === "No Pick" ? (
                                    <>
                                        <CloseIcon
                                            sx={{
                                                color: '#CC293C', // Customize color if needed
                                                width: '32px', // Adjust size if necessary
                                                height: '32px',
                                            }}
                                        />
                                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                                            <p style={{ margin: 0, color: '#CC293C' }}>NO PICK</p>
                                      </div>
                                    </>
                                ) : (
                                    <>
                                            <img
                                                className="team-logo-img"
                                                loading="lazy"
                                                alt={`${teamKey(team.teamId)} Logo`}
                                                src={teamLogo(team.teamId)}
                                            />
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <p
                                            style={{
                                                margin: 0,
                                                color: team.overallResult === "Loss" ? '#CC293C' : 
                                                    team.overallResult === "Win" ? '#00AA72' : '#002129'
                                            }}
                                            >{`${teamKey(team.teamId)}`}</p>
                                                <p
                                            style={{
                                                margin: 0,
                                                color: team.overallResult === "Loss" ? '#CC293C' : 
                                                    team.overallResult === "Win" ? '#00AA72' : '#002129'
                                            }}
                                            >{cfbOpponentInfo(team.gameId, team.teamId)}</p>
                                        </div>
                                    </>
                                )}
                            </div>
                        </TableCell>
                    )}

                    {!isMobile && (
                        <TableCell>
                            <div className="pick-stats-team-bar-desktop">
                                {team.teamId === "No Pick" ? (
                                    <>
                                        <CloseIcon
                                            sx={{
                                                color: '#CC293C', // Customize the color if needed
                                                width: '40px', // Adjust the size if necessary
                                                height: '40px',
                                            }}
                                        />
                                        <div className="pick-stats-team-text-container-desktop">
                                            <div className="selected-team-desktop" style={{ color: '#CC293C' }}>
                                                NO PICK
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <img
                                            className="team-logo-desktop-img"
                                            loading="lazy"
                                            alt={`${teamKey(team.teamId)} Logo`}
                                            src={teamLogo(team.teamId)}
                                        />
                                            <div className="pick-stats-team-text-container-desktop" style={{ color: team.overallResult === "Loss" ? '#CC293C' : team.overallResult === "Win" ? '#00AA72' : '#002129' }}>                                            <div className="selected-team-desktop">
                                                {`${teamKey(team.teamId)} ${cfbOpponentInfo(team.gameId, team.teamId)}`}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </TableCell>
                    )}

                    {!isMobile && (
                        <TableCell>
                            <Typography
                                sx={{
                                    fontSize: '22px',
                                    fontWeight: '500',
                                    textAlign: 'center',
                                    color: team.overallResult === "Loss" ? '#CC293C' : team.overallResult === "Win" ? '#00AA72' : '#002129',
                                }}
                            >
                                {team.timesSelected}
                            </Typography>
                        </TableCell>
                    )}
                    {!isMobile && (
                        <TableCell>
                            <PercentagePillBox
                                percentage={(team.timesSelected / totalWeeklySelections) * 100}
                                result={team.overallResult}
                            />
                        </TableCell>
                    )}
                    {isMobile && (
                        <TableCell>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100%', // Ensures full height of the TableCell is used
                                    textAlign: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        color: team.overallResult === "Loss" ? '#CC293C' : team.overallResult === "Win" ? '#00AA72' : '#002129',
                                    }}
                                >
                                    {team.timesSelected}
                                </Typography>
                                <Box
                                    sx={{
                                        mt: 1, // Margin top for spacing
                                    }}
                                >
                                    <PercentagePillBox
                                        percentage={(team.timesSelected / totalWeeklySelections) * 100}
                                        result={team.overallResult}
                                    />
                                </Box>
                            </Box>
                        </TableCell>
                    )}
                </TableRow>
            ))}
            {sortedTeamStats?.length === 0 && (
                        <TableRow>
                            <TableCell colSpan={4} style={{ textAlign: 'center', padding: '20px', color: '#002129' }}>
                            <Typography
                                sx={{
                                    fontSize: isMobile ? '16px' : '20px',
                                    fontWeight: 700,
                                    marginBottom: '20px'
                                }}
                            >
                                {isMobile
                                    ? (
                                        <>
                                            Team Stats will be available upon the <br />
                                            game start for each team.
                                        </>
                                    )
                                    : 'Team Stats will be available upon the game start for each team.'
                                }
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: isMobile ? '16px' : '20px',
                                    fontWeight: 700,
                                }}
                            >
                                {isMobile
                                    ? (
                                        <>
                                            Only teams that have been picked will <br />
                                            appear in this table.
                                        </>
                                    )
                                    : 'Only teams that have been picked will appear in this table.'
                                }
                            </Typography>
                            </TableCell>
                        </TableRow>
                    )}
        </TableBody>
    </Table>
</TableContainer>

    </Box>

  );
};

export default CFBSurvivorTeamStats;