import React, { createContext, useContext, useState, useEffect } from "react";
import { useGetUserData, useGetGameHook, useCreateSubgroup } from "../../BirdiePool/hooks/index";
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userLogoutAction } from '../../../redux/actions/loginImport';
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import { getUserDataFromLocalStorage } from "../../../redux/actions/loginImport";
import { useNFLAreGamesInProgress, useNFLCurrentWeek, useNFLOddsByWeek, useNFLScoresBySeason, useNFLStadiums, useNFLStandings, useNFLTeams } from "../../../hooks/nfl/index";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import { useGetPoolLeaderboard } from '../../../hooks/pools/useGetPoolLeaderboard';
import { useGetPoolDeadlines } from "../../../hooks/pools/useGetPoolDeadlines";
import { DateTime } from 'luxon';
import { pick, set } from "lodash";



const NFLSurvivorContext = createContext();

export const useNFLSurvivorContext = () => {
  const context = useContext(NFLSurvivorContext);
  if (!context) {
    throw new Error("useNFLSurvivorContext must be used within a NFLSurvivorProvider");
  }
  return context;
};

const NFLSurvivorProvider = ({ children }) => {
  const myUserId = getUserDataFromLocalStorage()?.user_id;
    //console.log("USER DATA ID", myUserId);
  const { mutate: fetchUserData, data: userData, isLoading: userLoading, isError: userError, error: userErrorData } = useGetUserData();
  const { mutate: fetchGame, data: gameData, isLoading: gameLoading, isError: gameError, error: gameErrorData } = useGetGameHook();
  const { mutate: fetchLeaderboardData, data: leaderboardData, isLoading: leaderboardLoading, isError: leaderboardError, error: leaderboardErrorData } = useGetPoolLeaderboard();
  const { mutate: createSubgroup, data: subgroupData, isLoading: subgroupLoading, isError: subgroupError, error: subgroupErrorData } = useCreateSubgroup();
  const { mutate: fetchAreGamesInProgress, data: areGamesInProgress, isLoading: areGamesInProgressLoading, isError: areGamesInProgressError, error: areGamesInProgressErrorData } = useNFLAreGamesInProgress();
  const { mutate: fetchCurrentWeek, data: currentWeek, isLoading: currentWeekLoading, isError: currentWeekError, error: currentWeekErrorData } = useNFLCurrentWeek();
  const { mutate: fetchNFLGameOddsByWeek, data: NFLGameOddsByWeek, isLoading: NFLGameOddsByWeekLoading, isError: NFLGameOddsByWeekError, error: NFLGameOddsByWeekErrorData } = useNFLOddsByWeek();
  const { mutate: fetchNFLScoresBySeason, data: NFLScoresBySeason, isLoading: NFLScoresBySeasonLoading, isError: NFLScoresBySeasonError, error: NFLScoresBySeasonErrorData } = useNFLScoresBySeason();
  const { mutate: fetchNFLStadiums, data: NFLStadiums, isLoading: NFLStadiumsLoading, isError: NFLStadiumsError, error: NFLStadiumsErrorData } = useNFLStadiums();
  const { mutate: fetchNFLStandings, data: NFLStandings, isLoading: NFLStandingsLoading, isError: NFLStandingsError, error: NFLStandingsErrorData } = useNFLStandings();
  const { mutate: fetchNFLTeams, data: NFLTeams, isLoading: NFLTeamsLoading, isError: NFLTeamsError, error: NFLTeamsErrorData } = useNFLTeams();
  const { mutate: fetchPoolDeadlines, data: poolDeadlines, isLoading: poolDeadlinesLoading, isError: poolDeadlinesError, error: poolDeadlinesErrorData } = useGetPoolDeadlines();
  const { gameId, component, pageState } = useParams();
  const gameType = "Survivor";
  const gameAddress = "nfl-survivor";

  const navigateToTop = useNavigateToTop();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const uriEncoded = (link) => {
    return encodeURI(`${encodeURIComponent(link)}`);
  }

   // Fetch user data first
   useEffect(() => {
    if (myUserId) {
        fetchUserData(myUserId);
    }
  }, [fetchUserData, myUserId]);

  //console.log("USER DATA", userData); 
  // Find the game in the user data
  let doesUserBelong = false;
  const thisGame = userData?.Games?.find(game => game.gameID === gameId);
  if (thisGame) {
    doesUserBelong = true;
  }
  //console.log("THIS GAME", thisGame, "Does User Belong", doesUserBelong);
  // Get the user's username, their picks, the stop picks warning, and default party
  const myUsername = userData?.user_name;
  const userPicksFromDB = thisGame?.pool?.picks || [];
  //console.log("User Picks from DB", userPicksFromDB);
  const stopPicksWarning = thisGame?.pool?.stopPicksWarning || false;
  // Utility function to check if defaultPartyIdInDB is in userSubgroups
  const isDefaultPartyInUserSubgroups = (partyId, subgroups) => {
    return subgroups.some(subgroup => subgroup.subGroupID === partyId);
  };

  
  const doesUserHaveALoss = userPicksFromDB?.some((pick) => pick.result === "Loss");
  let lossWeek = null;
  let losingGame = null;
  if (doesUserHaveALoss) {
    losingGame = userPicksFromDB?.find((pick) => pick.result === "Loss");
    lossWeek = losingGame?.week;
  }
  //console.log("DoesUserHaveALoss:", doesUserHaveALoss, "LossWeek:", lossWeek);
  
  
  // Get the default party ID with the additional check
  let defaultPartyIdInDB = Array.isArray(thisGame?.pool?.defaultParty) && thisGame?.pool?.defaultParty?.length > 0
    ? thisGame.pool.defaultParty[0]
    : gameId;

  //console.log("Default Party Id in DB", thisGame?.pool?.defaultParty);
  if (defaultPartyIdInDB !== gameId && !isDefaultPartyInUserSubgroups(defaultPartyIdInDB, thisGame?.subGroups || [])) {
    defaultPartyIdInDB = gameId;
  }
  //console.log("Default Party Id", defaultPartyIdInDB);

  //Get info about user subgroups
  const userSubgroups = thisGame?.subGroups || [];
  //console.log("User Subgroups in Context Party", userSubgroups);
  const firstSubgroupId = userSubgroups[0]?.subGroupID || "";
  const firstSubgroupName = userSubgroups[0]?.name || "";
  // Get the last subgroup if there are any subgroups
  const lastIndex = userSubgroups.length - 1;
  const lastSubgroupId = lastIndex >= 0 ? userSubgroups[lastIndex]?.subGroupID || "" : "";
  const lastSubgroupName = lastIndex >= 0 ? userSubgroups[lastIndex]?.name || "" : "";
  //console.log("First Subgroup ID", firstSubgroupId, "First Subgroup Name", firstSubgroupName, "Last Subgroup ID", lastSubgroupId, "Last Subgroup Name", lastSubgroupName);


  let defaultPartyNameInDB = "Pool Party";
  if (defaultPartyIdInDB !== gameId) {
    const foundSubGroup = userSubgroups.find(subgroup => subgroup.subGroupID === defaultPartyIdInDB);
    defaultPartyNameInDB = foundSubGroup ? foundSubGroup.name : "Pool Party";
  }
  //console.log("Default Party Name", defaultPartyNameInDB);

  //console.log("My Party Default Id", defaultPartyIdInDB, "My Party Default Name", defaultPartyNameInDB);


  // Fetch game data and leaderboardData second
  useEffect(() => {
    if (gameType && gameId) {
        fetchGame( {gameType, gameID: gameId });
        fetchLeaderboardData({ gameID: gameId });
    }
  }, [fetchGame, fetchLeaderboardData, gameId, gameType]); //Add fetchLeaderboardData once it's ready

  const [pickDeadlines, setPickDeadlines] = useState([]);
  //console.log("Pick Deadlines BEFORE useEffect", pickDeadlines);
  // Fetch pool deadlines
  useEffect(() => {
    if (gameData) {
      fetchPoolDeadlines({ league: gameData?.league });
    }
  }, [fetchPoolDeadlines, gameData]);

  useEffect(() => {
    //console.log("Setting Pick Deadlines");
    if (poolDeadlines) {
      setPickDeadlines(poolDeadlines);
    }
  }, [poolDeadlines]);
  //console.log("Pick Deadlines AFTER useEffect", pickDeadlines);

  // Set loading to false when game and leaderboard data are fetched & Add leaderboardData Back!!!!!!!!!!!!!!!!!!!
  useEffect(() => {
    if (gameData  && leaderboardData && poolDeadlines) {
        setLoading(false);
    }
  }, [gameData, leaderboardData, poolDeadlines]);

  // Error handling for game fetch
  useEffect(() => {
    if (gameError) {
        console.log("Error fetching game data:", gameErrorData);
        if (gameErrorData.response.status === 401) {
            dispatch(userLogoutAction({
              logoutType: 'token-expired'
            }));
          } else {
            navigateToTop('/404/pool-hall');
        }
    }

    if (gameData) {
      //console.log("Game Data recieved:", gameData);
    }
  }, [gameError, gameErrorData, gameData, dispatch, navigateToTop]);

  // Error handling for leaderboard fetching
  useEffect(() => {
    if (leaderboardError) {
        console.error("An error occurred while fetching the leaderboard:", leaderboardErrorData);
        if (leaderboardErrorData.response?.status === 401) {
            dispatch(userLogoutAction({
                logoutType: 'token-expired'
            }));
        } else {
            navigateToTop('/404/pool-hall');
        }
    }

    if (leaderboardData) {
        //console.log("Leaderboard data received:", leaderboardData);
    }
  }, [leaderboardData, leaderboardError, leaderboardErrorData, dispatch]);

  //console.log("GameParams:", gameData?.gameParameters[0]);
  const firstWeek = gameData?.gameParameters[0]?.startingWeek;
  //console.log("First Week", firstWeek);
  const lastDayToJoin = gameData?.lastDayToJoin;
  const lastDayToJoinET = new Date(lastDayToJoin).toLocaleString('en-US', {
    timeZone: 'America/New_York',    // ET Timezone
    weekday: 'long',                 // Optional: shows full weekday name (e.g., "Sunday")
    year: 'numeric',
    month: 'long',                   // Optional: shows full month name (e.g., "October")
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true                     // Optional: 12-hour format
  });
  const sponsorName = gameData?.sponsorName;
  const sponsorLogo = gameData?.sponsorLogo;
  const gameName = gameData?.gameName;
  //console.log("Game Date", gameData, "First Week", firstWeek, "Last Day to Join ET", lastDayToJoinET, "Sponsor Name", sponsorName, "Sponsor Logo", sponsorLogo);

  let startingWeekState = currentWeek ? currentWeek : 1;
    
  const [pickEmState, setPickEmState] = useState([]);
  const [week, setWeek] = useState(startingWeekState);
  const [unsavedPicksModal, setUnsavedPicksModal] = useState(false);
  const [clickState, setClickState] = useState(null);
  const [signUpState, setSignUpState] = useState(false);
  const [partyState, setPartyState] = useState("");
  const [partyId, setPartyId] = useState(gameId);
  const [defaultPartyId, setDefaultPartyId] = useState(null);
  const [defaultPartyName, setDefaultPartyName] = useState(null);
  const [joinOpen, setJoinOpen] = useState(false);

  const [isPickEmStateInitialized, setIsPickEmStateInitialized] = useState(false);

  useEffect(() => {
    if (userData && gameId) {
      const thisGame = userData?.Games?.find(game => game.gameID === gameId);
      if (thisGame && thisGame.pool?.picks && !isPickEmStateInitialized) {
        setPickEmState(thisGame.pool.picks);
        setIsPickEmStateInitialized(true);
      }
    }
  }, [userData, gameId, isPickEmStateInitialized]);


  useEffect(() => {
    if (defaultPartyIdInDB && defaultPartyNameInDB) {
      setDefaultPartyId(defaultPartyIdInDB);
      setDefaultPartyName(defaultPartyNameInDB);
    }
  }, [defaultPartyIdInDB, defaultPartyNameInDB]);


  useEffect(() => {
    fetchAreGamesInProgress();
    fetchCurrentWeek();
    fetchNFLGameOddsByWeek();
    fetchNFLScoresBySeason();
    fetchNFLStadiums();
    fetchNFLStandings();
    fetchNFLTeams();
  }, [fetchAreGamesInProgress, fetchCurrentWeek, fetchNFLGameOddsByWeek, fetchNFLScoresBySeason, fetchNFLStadiums, fetchNFLStandings, fetchNFLTeams]);

  //console.log("areGamesInProgress", areGamesInProgress, "Current Week:", currentWeek, "NFLGameOddsByWeek", NFLGameOddsByWeek, "NFLScoresBySeason", NFLScoresBySeason, "NFLStadiums", NFLStadiums, "NFLStandings", NFLStandings, "NFLTeams", NFLTeams);

  //Use Effect for setting the week and party state based on the component and page state
  useEffect(() => {
    if (userData && gameData && component) {
      if (component === "MY_PICKS") {
        // If the page state is not "CURRENT", set the week to the page state, otherwise Current goes to current week or first week, whichever is greater
        if (pageState !== "CURRENT") {
          const weekNumber = parseInt(pageState, 10);
          setWeek(weekNumber);
        } else if (firstWeek > startingWeekState) {
          setWeek(firstWeek);
        } else {
          setWeek(startingWeekState);
        }
        setPartyId(defaultPartyId);
        setPartyState(defaultPartyName);
      }

      if (component === "LEADERBOARD" || component === "PICK_STATS") {
        if (pageState !== gameId) {
          const pageStateInUserSubgroups = userSubgroups?.find(subgroup => subgroup.subGroupID === pageState);
          if (pageStateInUserSubgroups) {
            setPartyId(pageState);
            setPartyState(pageStateInUserSubgroups.name);
          } else {
            setPartyId(gameId);
            setPartyState("Pool Party");
            navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded(component)}/${uriEncoded(gameId)}`);
          }
        }
      }

      if (component === "HOME") {
        setPartyId(defaultPartyId);
        setPartyState(defaultPartyName);
      }

      if (component === "MY_PARTY") {
        if (pageState === "MY_PARTY") {
          setPartyId(defaultPartyId);
          setPartyState(defaultPartyName);
          setSignUpState(true);
        } else if (pageState === "NEW") {
          //console.log("Last Subgroup ID", lastSubgroupId, "Last Subgroup Name", lastSubgroupName);
          setPartyId(lastSubgroupId);
          setPartyState(lastSubgroupName);
          navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded('MY_PARTY')}/${uriEncoded(lastSubgroupId)}`);
        } else {
          const subgroupName = userSubgroups?.find(subgroup => subgroup.subGroupID === pageState)?.name;
          setPartyId(pageState);
          setPartyState(subgroupName || "Unnamed Subgroup");
        }
      }
    }
  }, [userData, gameData, component, pageState, startingWeekState, defaultPartyId, firstSubgroupId, lastSubgroupId]);

  //console.log("userData", userData, "gameData", gameData, "component", component, "pageState", pageState, "startingWeekState", startingWeekState, "defaultPartyId", defaultPartyId, "firstSubgroupId", firstSubgroupId, "lastSubgroupId", lastSubgroupId, "partyId", partyId, "partyState", partyState);

  // Updated generate week options to account for games that may not being at week 1
  const generateWeekOptions = (startingWeek) => {
    const options = [];
    // Start from the maximum of firstWeek or startingWeek
    const weekToStart = Math.max(startingWeek, firstWeek);
    for (let week = weekToStart; week >= firstWeek; week--) {
        options.push(<option key={week} value={`Week ${week}`}>{`Week ${week}`}</option>);
    }
    return options;
}


  const weekOptions = generateWeekOptions(startingWeekState);
  //console.log("Week Options", weekOptions);


    const lockedBackgroundColor = "#002129"
    const winBackgroundColor = "#00AA72";
    const lossBackgroundColor = "#CC293C";
    const pushBackgroundColor = "rgba(35, 36, 37, 0.75)";
    const winNotSelectedBackgroundColor = "#CCFFEF";
    const pushNotSelectedBackgroundColor = "#BFC7C9";

    const finalizedMyPickBorderColor = "#D6D2D2";
    const finalizedPushBorderColor = "black";
    const finalizedWinBorderColor = "#00AA72";

    const lockedPickFontColor = "#FFF";
    const notSelectedStartedFontColor = "#BDBDBD";
    const notSelectedPushFontColor = "#002129";

    const topLeftLiveColor = "#CC293C";
    const topLeftNotSelectedColor = "#9D9D9D";
    const topLeftSelectedColor = "white";

    const pendingBoxColor = "#232425";
    const winBoxColor = "#00AA72";
    const winBoxBackgroundColor = "rgba(0, 170, 114, 0.20)";
    const lossBoxColor = "#CC293C";
    const lossBoxBackgroundColor = "rgba(204, 41, 60, 0.20)";
    const pushBoxColor = "#5A5B5C";
    const pushBoxBackgroundColor = "rgba(90, 91, 92, 0.20)";


    const handleCreateSubgroup = (subgroupData) => {
      createSubgroup(subgroupData);
    };

    //console.log("Leaderboard Data", leaderboardData);

    const howManyLeftOnLeaderboard = leaderboardData?.filter((entry) => entry?.doesUserHaveALoss === false).length;
    
    //console.log("How Many Left on Leaderboard", howManyLeftOnLeaderboard);
    //console.log("NFL Scores By Season", NFLScoresBySeason);
    const firstWeekGames = NFLScoresBySeason?.filter((week) => week.Week === firstWeek);
    const areAllFirstWeekGamesFinal = firstWeekGames?.every((game) => game.Status === "Final" || game.Status === "F/OT");
    //console.log("First Week Games", firstWeekGames, "Are all First Week Games Final?", areAllFirstWeekGamesFinal);
    const weekEighteenGames = NFLScoresBySeason?.filter((week) => week.Week === 18);
    const areAllWeekEighteenGamesFinal = weekEighteenGames?.every((game) => game.Status === "Final" || game.Status === "F/OT");
    //console.log("Week Eighteen Games", weekEighteenGames, "Are all Week 18 games Final?", areAllWeekEighteenGamesFinal);
    const isSurvivorOver = ((howManyLeftOnLeaderboard === 1 || howManyLeftOnLeaderboard === 0) && areAllFirstWeekGamesFinal) || areAllWeekEighteenGamesFinal;
    //console.log("Is Survivor Over?", isSurvivorOver);
    const leaderboardSortedForFirst = leaderboardData
    ?.sort((a, b) => {
      // First, sort by doesUserHaveALoss (false should come before true)
      if (a.doesUserHaveALoss !== b.doesUserHaveALoss) {
        return a.doesUserHaveALoss - b.doesUserHaveALoss;
      }
      
      // Then sort by totalWins (in descending order)
      if (a.totalWins !== b.totalWins) {
        return b.totalWins - a.totalWins;
      }
      
      // Finally, sort by totalMargin (in descending order)
      return b.totalMargin - a.totalMargin;
    })[0];

    const nowInNewYork = DateTime.now().setZone('America/New_York');
    // Define the date and time in New York timezone
    // const deadlineTimeInNewYork = DateTime.fromObject({
    //     year: 2024,
    //     month: 9, // September
    //     day: 8,
    //     hour: 13, // 1 PM in 24-hour format
    //     minute: 0,
    //     second: 0,
    //     millisecond: 0,
    // }, { zone: 'America/New_York' });
    // const hasJoinDeadlinePassed = nowInNewYork > deadlineTimeInNewYork;
    const lastDayToJoinDateTime = DateTime.fromISO(lastDayToJoin).setZone('America/New_York');

    const hasJoinDeadlinePassed = nowInNewYork > lastDayToJoinDateTime;
    //console.log("Has Join Deadline Passed Test?", hasJoinDeadlinePassed, "Last Day to Join ET", lastDayToJoinET, "Last Day to Join", lastDayToJoin);
    
    
    const whosInFirst = leaderboardSortedForFirst?.username;
    const whosInFirstCharacterCount = whosInFirst?.length;
    //console.log("Whos in First", whosInFirst, "Whos in First Character Count", whosInFirstCharacterCount, "Has Join Deadline Passed?", hasJoinDeadlinePassed);

  const NFLSurvivorContextValues = {
    fetchUserData,
    fetchNFLGameOddsByWeek,
    gameData: gameData,
    userData: userData,
    myUserId,
    myUsername,
    mainGameId: gameId,
    gameType,
    gameAddress,
    doesUserBelong,
    weekOptions,
    NFLTeams,
    NFLStandings,
    NFLStadiums,
    NFLGameOddsByWeek,
    //NFLSchedules,
    NFLScoresBySeason,
    doesUserHaveALoss,
    lossWeek,
    losingGame,
    joinOpen,
    setJoinOpen,
    pickEmState,
    setPickEmState,
    startingWeekState,
    week,
    setWeek,
    unsavedPicksModal,
    setUnsavedPicksModal,
    clickState,
    setClickState,
    signUpState,
    setSignUpState,
    partyState,
    setPartyState,
    partyId,
    setPartyId,
    defaultPartyId,
    setDefaultPartyId,
    defaultPartyName,
    setDefaultPartyName,
    firstSubgroupId,
    firstSubgroupName,
    userPicksFromDB,
    stopPicksWarning,
    lockedBackgroundColor,
    winBackgroundColor,
    lossBackgroundColor,
    pushBackgroundColor,
    winNotSelectedBackgroundColor,
    pushNotSelectedBackgroundColor,
    finalizedMyPickBorderColor,
    finalizedPushBorderColor,
    finalizedWinBorderColor,
    lockedPickFontColor,
    notSelectedStartedFontColor,
    notSelectedPushFontColor,
    topLeftLiveColor,
    topLeftNotSelectedColor,
    topLeftSelectedColor,
    pendingBoxColor,
    winBoxColor,
    winBoxBackgroundColor,
    lossBoxColor,
    lossBoxBackgroundColor,
    pushBoxColor,
    pushBoxBackgroundColor,
    handleCreateSubgroup,
    userSubgroups,
    leaderboardInfo: leaderboardData,
    leaderboardSortedForFirst,
    whosInFirst,
    whosInFirstCharacterCount,
    isSurvivorOver,
    hasJoinDeadlinePassed,
    firstWeek,
    lastDayToJoinET,
    sponsorName,
    sponsorLogo,
    areAllFirstWeekGamesFinal,
    gameName,
    pickDeadlines,
  };
  //console.log("NFL Survivor Context Values", NFLSurvivorContextValues);

  //Render loading screen while fetching data  ADD LEADEBOARDLOADING BACK IN!!!!!!!!!!!!!!!!!!!
  if (loading || userLoading || gameLoading || !gameData || areGamesInProgressLoading || currentWeekLoading || NFLGameOddsByWeekLoading || NFLScoresBySeasonLoading || NFLStadiumsLoading || NFLStandingsLoading || NFLTeamsLoading) {
    return <LoadingScreen />;
  }


  return (
    <NFLSurvivorContext.Provider value={NFLSurvivorContextValues}>
      {children}
    </NFLSurvivorContext.Provider>
  );
};

export default NFLSurvivorProvider;