import React, { useState } from 'react';
import { Box, Typography, Modal, Button } from '@mui/material'; // Import MUI components
import SponsorSelectionButtons from './SponsorSelectionButtons';
import SponsorStoryPage from './SponsorStoryPage';
import SponsorHighlightsPage from './SponsorHighlightsPage';
import './SponsorTab.scss'; // Import SCSS file

const SponsorTab = ({ gameData }) => {
    
    const mainGameId = gameData?.gameID;
    const gameAddress = gameData?.gameAddress;
    const sponsorName = gameData?.sponsorInfo?.sponsorName;
    const sponsorAbbreviation = gameData?.sponsorInfo?.sponsorAbbreviation;
    const sponsorWebsite = gameData?.sponsorWebsite;
    const buttonsToDisplay = [`${sponsorAbbreviation} Story`, 'Highlights'];
    const sponsorInfo = gameData?.sponsorInfo;
    const sponsorTabImage = gameData?.sponsorInfo?.sponsorTabImage;
    //console.log("Sponsor Info: ", sponsorInfo);


    const [sponsorPageState, setSponsorPageState] = useState(buttonsToDisplay[0]);
    //console.log(sponsorPageState);
   

  return (
    <>
        <Box className="sponsor-tab-image-container">
            <img
                src={sponsorTabImage}
                alt={sponsorName}
                className="sponsor-tab-sponsor-header-image"
            />
        </Box>
        <Typography className="sponsor-tab-sponsor-link">
            <a href={sponsorWebsite} target="_blank" rel="noopener noreferrer">
            {`${sponsorName} Website`}
            </a>
      </Typography>
        <SponsorSelectionButtons 
            sponsorPageState={sponsorPageState} 
            setSponsorPageState={setSponsorPageState} 
            buttonsToDisplay={buttonsToDisplay}
            mainGameId={mainGameId}
            gameAddress={gameAddress}
        />

        {sponsorPageState === `${sponsorAbbreviation} Story` && 
            <SponsorStoryPage
                sponsorInfo={sponsorInfo}
            />
        }
        {sponsorPageState === 'Highlights' && 
            <SponsorHighlightsPage
                dishes={sponsorInfo?.dishes}
                storyBottomText={sponsorInfo?.storyBottomText}
                highlightsText={sponsorInfo?.highlightsText}    
            />
        }
    </>
  );
}

export default SponsorTab;