import React from 'react';
import { Box, Typography } from '@mui/material'; // Import Box and Typography from Material-UI
import './GameRulesPage.scss'; // Import the SCSS file

const GameRulesPagePrizes = ({ prizes }) => {
    //console.log("Rules Page", prizes);
  return (
    <Box className="rules-page-prizes-container"> {/* Added className to Box */}
      
      <Typography 
        variant="body1" 
        className="rules-page-prizes-header"
      >
        Pool Prizes
      </Typography>

    {prizes?.firstPlace && (
      <Typography 
        variant="body1" 
        className="rules-page-prizes-text"
      >
        <span className="rules-prize-text">
          <span className="rules-highlight">1st Place</span> - {prizes?.firstPlace}
        </span>
      </Typography>
    )}
    {prizes?.secondPlace && (
      <Typography 
        variant="body1" 
        className="rules-page-prizes-text"
      >
        <span className="rules-prize-text">
          <span className="rules-highlight">2nd Place</span> - {prizes?.secondPlace}
        </span>
      </Typography>
    )}
    {prizes?.thirdPlace && (
      <Typography 
        variant="body1" 
        className="rules-page-prizes-text"
      >
        <span className="rules-prize-text">
          <span className="rules-highlight">3rd Place</span> - {prizes?.thirdPlace}
        </span>
      </Typography>
    )}
    {prizes?.lastPlace && (
      <Typography 
        variant="body1" 
        className="rules-page-prizes-text"
      >
        <span className="rulesprize-text">
          <span className="rules-highlight">Last Place</span> - {prizes?.lastPlace}
        </span>
      </Typography>
    )}
    </Box>
  );
}

export default GameRulesPagePrizes;
