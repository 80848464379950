import React, { useState } from 'react';
import { Box, Typography } from '@mui/material'; 
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'; 
import './SponsorTab.scss';

const SponsorLocations = ({ location, address1, address2, phoneNumber, hours, extraInfo }) => {
    const [locationOpen, setLocationOpen] = useState(false);

    const toggleLocation = () => {
        setLocationOpen(prevState => !prevState);
    };

    return (
        <Box className="sponsor-tab-locations-container">
            <Box className="sponsor-tab-header" onClick={toggleLocation}>
                <Typography className='sponsor-tab-location-text'>
                    {location}
                </Typography>
                <KeyboardArrowDownIcon 
                    className={`sponsor-tab-icon ${locationOpen ? 'open' : ''}`}
                />
            </Box>

            {locationOpen && (
                <>
                    <Typography className='sponsor-tab-main-text-first'>
                        {address1}
                    </Typography>
                    <Typography className='sponsor-tab-main-text'>
                        {address2}
                    </Typography>
                    <Typography className='sponsor-tab-main-text'>
                        {phoneNumber}
                    </Typography>

                    {/* Hours Section */}
                    <Box className="sponsor-tab-hours">
                        {hours.map((time, index) => (
                            <Box key={index} className="sponsor-tab-hours-block">
                                <Typography className='sponsor-tab-main-text'>
                                    {time?.days}
                                </Typography>
                                <Typography className='sponsor-tab-main-text'>
                                    {time?.hours}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                    <Typography className='sponsor-tab-main-text'>
                        {extraInfo}
                    </Typography>
                </>
            )}
        </Box>
    );
};

export default SponsorLocations;
