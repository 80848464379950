import React, { useState } from "react";
import { Typography, Box, Modal, Button } from "@mui/material";
import { useJoinPublicPool } from "../../PublicPools/hooks/useJoinPublicPools";
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import CreatePartyInfo from "../../MyParty/CreatePartyInfo";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch } from "react-redux";
import { userLogoutAction } from "../../../redux/actions/loginImport";

const JoinGameModal = ({ myUserId, logo, gameName, gameType, gameId, gameAddress, joinOpen, setJoinOpen, setSignUpState, sponsorSecondaryLogo, sponsorName }) => {
    console.log("Sponsor Name: ", sponsorName);
    const navigateToTop = useNavigateToTop();

    const { mutate: joinPool } = useJoinPublicPool();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();

    const [status, setStatus] = useState('confirmJoin');
    const [errorMessage, setErrorMessage] = useState([]);//UI Purposes

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    const joinGame = async (gameID, gameType) => {
        let data = {
            gameID: gameID,
            userID: myUserId,
            gameType: gameType
        };
    
        joinPool(data, {
            onSuccess: () => {
                setJoinOpen(true);
                setStatus("success");
                // Optionally, navigate to the game page or handle success logic
            },
            onError: (error) => {
                // Dispatch logout action for any error
                dispatch(userLogoutAction({
                    logoutType: 'token-expired'
                }));
        
                // No need to handle status or error message since the user is logged out
                console.error("Join pool failed:", error);
            }
        });
        
    };
    
    
    
    const handleJoinPool = (gameID, gameType) => {
        if(!myUserId) {
            navigateToTop(`/login/${gameAddress}/${uriEncoded(gameID)}/HOME/HOME`);
        } else {
            joinGame(gameID, gameType);
            //console.log("JOIN POOL HERE")
        }
    };

    return (
        <div>
            <Modal
                open={joinOpen && status === "confirmJoin"}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Box
                    sx={{ 
                        position: 'absolute', 
                        top: '50%', 
                        left: '50%', 
                        transform: 'translate(-50%, -50%)', 
                        width: '308px',
                        minHeight: isMobile ? '330px' : '350px',
                        height: 'auto',
                        bgcolor: '#FFF', 
                        borderRadius: '8px', 
                        boxShadow: 24, 
                        p: 2, // Padding of 16px
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2,
                    }}
                >
                    {/* <Typography
                        variant="h6"
                        sx={{ 
                            color: '#002129',
                            textAlign: 'center',
                            fontSize: '16px',
                            fontWeight: 800,
                            marginBottom: '16px'
                        }}
                    >
                        Join {gameName}
                    </Typography> */}
                    {sponsorSecondaryLogo && (
                        <img
                            src={sponsorSecondaryLogo}
                            alt={sponsorName}
                            style={{
                                width: '120px',
                                marginBottom: '16px'
                            }}
                        />
                    )}
                    <img 
                        src={logo}
                        alt={gameName}
                        style={{
                            width: '112px',
                            height: '140px',
                            marginBottom: '16px'
                        }}
                    />
                   <Typography
                        variant="h6"
                        sx={{ 
                            color: '#002129',
                            textAlign: 'center',
                            fontSize: '16px',
                            fontWeight: 400,
                            marginBottom: '16px'
                        }}
                    >
                        Would you like to join {sponsorName ? '' : "Pool Party's"} <br /> {gameName}?
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            gap: 2 // Gap of 20px
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                fontSize: '14px',
                                fontWeight: 700,
                                width: '115px',
                                height: '38px',
                                mb: 1 // MarginBottom of 8px
                            }}
                            onClick={() => setJoinOpen(false)}
                        >
                            CANCEL
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                fontSize: '14px',
                                fontWeight: 700,
                                width: '115px',
                                height: '38px',
                                whiteSpace: 'nowrap',
                                mb: 1 // MarginBottom of 8px
                            }}
                            onClick={() => handleJoinPool(gameId, gameType)}
                        >
                            JOIN POOL
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
            open={joinOpen && status === "success"}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Box 
                sx={{ 
                    position: 'absolute', 
                    top: '50%', 
                    left: '50%', 
                    transform: 'translate(-50%, -50%)', 
                    width: '308px',
                    maxHeight: '765px',
                    bgcolor: '#FFF', 
                    borderRadius: '8px', 
                    boxShadow: 24, 
                    p: 2, // Padding of 16px
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2, // Gap of 20px
                    overflow: 'scroll'
                }}
            >
                <Typography
                    variant="h6"
                    sx={{ 
                        color: '#002129',
                        textAlign: 'center',
                        fontSize: '20px',
                        fontWeight: 700,
                        marginBottom: '16px'
                    }}
                >
                    Welcome to {sponsorName ? '' : "Pool Party's"} {gameName}!
                </Typography>
                {sponsorSecondaryLogo && (
                    <img
                        src={sponsorSecondaryLogo}
                        alt={sponsorName}
                        style={{
                            width: '120px',
                            height: '73px',
                            marginBottom: '16px'
                        }}
                    />
                )}

                <img 
                    src={logo}
                    alt={gameName}
                    style={{
                        width: '112px',
                        height: '140px',
                        marginBottom: '16px'
                    }}
                />
                {/* <Typography
                    variant="h6"
                    sx={{ 
                        color: '#002129',
                        textAlign: 'center',
                        fontSize: '12px',
                        fontWeight: 700,
                        marginBottom: '16px'
                    }}
                >
                    You have joined the Pool Party universe<br/> site-wide competition.
                </Typography> */}
                {/* <Typography
                    variant="h6"
                    sx={{ 
                        color: '#002129',
                        textAlign: 'center',
                        fontSize: '12px',
                        fontWeight: 700,
                        marginBottom: '16px'
                    }}
                >
                    To create your custom group competition, click the button below.
                </Typography>

                <CreatePartyInfo setSignUpState={setSignUpState} gameAddress={gameAddress} doesUserBelong={true} gameName={gameName} setJoinOpen={setJoinOpen} refreshNeeded={true}/>

                <Typography
                    variant="h6"
                    sx={{ 
                        color: '#002129',
                        textAlign: 'center',
                        fontSize: '12px',
                        fontWeight: 700,
                        marginBottom: '16px',
                        marginTop: '8px'
                    }}
                >
                    Not looking to create a custom Party?
                </Typography> */}
                <Button
                    variant="contained"
                    sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        width: '182px',
                        height: '36px',
                        mb: 1 // MarginBottom of 8px
                    }}
                    onClick={() => window.location.href = `/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/HOME/HOME`}
                >
                    ENTER POOL HOME
                </Button>
            </Box>
        </Modal>

        <Modal
            open={joinOpen && status === "fail"}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Box 
                sx={{ 
                position: 'absolute', 
                top: '50%', 
                left: '50%', 
                transform: 'translate(-50%, -50%)', 
                width: '308px',
                height: '210px',
                bgcolor: '#FFF', 
                borderRadius: '8px', 
                boxShadow: 24, 
                p: 2, // Padding of 16px
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2, // Gap of 20px
                overflow: 'scroll'
                }}
            >
                <Typography
                style={{
                    color: '#002129',
                    textAlign: 'center',
                    fontSize: '24px',
                    fontWeight: 700,
                    marginBottom: '24px'
                }}
                >
                Oops!
                </Typography>
                <Typography
                style={{
                    color: '#002129',
                    textAlign: 'center',
                    fontSize: '12px',
                    fontWeight: 700,
                    marginBottom: '16px'
                }}
                >
                {errorMessage}
                </Typography>
                <Button
                variant="contained"
                sx={{
                    fontSize: '14px',
                    fontWeight: 700,
                    width: '152px',
                    height: '36px',
                    marginBottom: '8px'
                }}
                onClick={() => window.location.reload()} // Reload the page
                >
                Close
                </Button>
            </Box>
        </Modal>

        </div>
    );
}

export default JoinGameModal;