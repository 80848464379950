import React, { useState, useEffect } from "react";
import MyPicks from "./MyPicks/MyPicks";
import SurvivorNav from "./Headers/SurvivorNav";
import SurvivorHeader from "./Headers/SurvivorHeader";
import DynamicPoolHeaderBar from "../PoolHeaderBar/DynamicPoolHeaderBar";
import SurvivorUserDash from "./Headers/SurvivorUserDash";
import SurvivorHome from "./SurvivorHome/SurvivorHome";
import MyParty from "../MyParty/MyParty";
import PartyDropdown from "../PartyDropdown/MyPartyDropdown";
import SurvivorLeaderboard from "./Leaderboard/SurvivorLeaderboard";
import SurvivorPickStats from "./PickStats/SurvivorPickStats";
import NFLSurvivorRules from "./Rules/NFLSurvivorRules";
import { useNFLSurvivorContext } from "./context/NFLSurvivorContext";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useNavigateToTop from "../../hooks/general/useNavigateToTop";
import { DateTime } from "luxon";
import GameSponsorHeader from "../PoolHeaderBar/GameSponsorHeader";
import SponsorTab from "../SponsorTab/SponsorTab";
import GameRulesPage from "../GameRulesPage/GameRulesPage";
import JoinGameModal from "../GameModals/JoinGameModal/JoinGameModal";

const NFLSurvivorContainer = () => {
  const { component, groupStateCheck } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    gameData,
    myUserId,
    mainGameId,
    gameAddress,
    handleCreateSubgroup,
    userSubgroups, 
    signUpState,
    setSignUpState,
    NFLScoresBySeason,
    setWeek,
    startingWeekState, 
    clickState,
    setClickState,
    partyState,
    setPartyState,
    partyId,
    setPartyId,
    defaultPartyId,
    defaultPartyName,
    firstSubgroupId,
    firstSubgroupName,
    leaderboardInfo,
    doesUserBelong,
    joinOpen,
    setJoinOpen,
    isSurvivorOver,
    gameName,
    firstWeek,
    pickDeadlines,
    gameType 
  } = useNFLSurvivorContext();
  
  const logo = gameData?.clubLogo;
  const sponsorSecondaryLogo = gameData?.sponsorInfo?.sponsorSecondaryLogo;
  //console.log("Logo in Container: ", logo, "Sponsor Logo in Container: ", sponsorSecondaryLogo);


  useEffect(() => {
    // Update the clickState whenever the URL changes
    if (component) {
      setClickState(component);
    }
  }, [component, location]);

  const uriEncoded = (link) => {
    return encodeURI(`${encodeURIComponent(link)}`);
  }

  // // Getting the current week's Sunday 1:00 PM dealine
  // const currentWeeksSchedule = NFLScoresBySeason?.filter(game => 
  //   game.Week === (startingWeekState < firstWeek ? firstWeek : startingWeekState)
  // );
  // //console.log("Current Week's Schedule: ", currentWeeksSchedule, startingWeekState, firstWeek, startingWeekState < firstWeek);
  // const currentWeeksDeadline = currentWeeksSchedule?.find(game => {
  //     const gameDateTime = DateTime.fromISO(game.DateTime, { zone: 'America/New_York' });
  //     return gameDateTime.weekday === 7 && gameDateTime.hour === 13;
  // })?.DateTime;
  // console.log("Leaderboard Current Week's Deadline:", currentWeeksDeadline);
  
  const  currentWeeksDeadline = pickDeadlines?.find(deadline => 
    deadline.week === (startingWeekState < firstWeek ? firstWeek : startingWeekState)
  )?.deadline;
    //console.log("Test Deadline From Redis: ", currentWeeksDeadline);

  const currentWeeksDeadlineDateTime = currentWeeksDeadline 
  ? DateTime.fromISO(currentWeeksDeadline, { zone: 'America/New_York' }) 
  : null;
    //console.log("Current Week's Deadline DateTime: ", currentWeeksDeadlineDateTime);

  let fullLeaderboard = leaderboardInfo ? leaderboardInfo : [];
  fullLeaderboard?.sort((a, b) => (b.points || 0) - (a.points || 0));

  //console.log("Sorted Leaderboard in Container: ", fullLeaderboard);

  const [leaderboardData, setLeaderboardData] = useState(fullLeaderboard);

  useEffect(() => {
    if (partyId !== mainGameId) {
      //console.log("PartyId in Container: ", partyId, gameData);
      const subgroups = gameData?.subGroups
      const filteredSubgroups = subgroups?.filter(subgroup => subgroup.groupID === partyId);
      const filteredUserIds = filteredSubgroups?.[0]?.groupMembers;
      //console.log("Chosen Subgroup in Container: ", subgroups, filteredSubgroups, filteredUserIds);
      const filteredLeaderboard = fullLeaderboard?.filter(user => filteredUserIds?.includes(user.userId));
      //console.log("Filtered Leaderboard in Container: ", filteredLeaderboard);
      setLeaderboardData(filteredLeaderboard);
    } else {
      setLeaderboardData(fullLeaderboard);
    }
    }, [partyId, partyState, fullLeaderboard]);

  const navigateToTop = useNavigateToTop();

  const handleClickState = (click) => {
    setClickState(click);
    let pageStateToUse = partyId;
    if (click === 'MY_PICKS') {
      pageStateToUse = 'CURRENT';
      setWeek(startingWeekState);
      setPartyState(defaultPartyName);
      setPartyId(defaultPartyId);
    } else if (click === 'HOME') {
      pageStateToUse = 'HOME';
      setPartyState(defaultPartyName);
      setPartyId(defaultPartyId);
    }
    if (click === 'MY_PARTY' && userSubgroups.length === 0) {
      pageStateToUse = 'NO_PARTY';
    } else if (click === 'MY_PARTY' && partyId === mainGameId) {
      if (defaultPartyName !== 'Pool Party') {
        pageStateToUse = defaultPartyId;
        setPartyState(defaultPartyName);
        setPartyId(defaultPartyId);
      } else {
        pageStateToUse = firstSubgroupId;
        setPartyState(firstSubgroupName);
        setPartyId(firstSubgroupId);
      }
    }
    if (click === 'PRESENTED_BY') {
      pageStateToUse = `${gameData?.sponsorInfo?.sponsorAbbreviation} Story`;
    }
    navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded(click)}/${uriEncoded(pageStateToUse)}`);
  }

  //const howManyLeftOnLeaderboard = leaderboardData?.filter(user => user.doesUserHaveALoss === false).length;
  //console.log("How Many Left on Leaderboard: LEADERBOARD", howManyLeftOnLeaderboard);

  const sponsorLogo = gameData?.sponsorInfo?.sponsorLogo;
  const sponsorBannerText = gameData?.sponsorInfo?.sponsorBannerText;
  const sponsorName = gameData?.sponsorInfo?.sponsorName;
  const sponsorAbbreviation = gameData?.sponsorInfo?.sponsorAbbreviation;

  return (
    <div>
      {/* Conditional Sponsor Game Header */}
      {sponsorLogo && 
        <GameSponsorHeader 
          sponsorLogo={sponsorLogo} 
          sponsorBannerText={sponsorBannerText} 
          sponsorName={sponsorName}
          mainGameId={mainGameId}
          gameAddress={gameAddress}
          myUserId={myUserId}
          setJoinOpen={setJoinOpen}
        />
      }

      {/* <SurvivorHeader /> */}
      <DynamicPoolHeaderBar
        clickState={clickState}
        navigationText={`Home`}
        headerText={gameName}
        gameAddress={gameAddress}
        gameId={mainGameId}
      />

      {myUserId && <SurvivorUserDash currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime}/>}

      <SurvivorNav 
        setClickState={handleClickState} 
        clickState={clickState} 
        setSignUpState={setSignUpState}
        doesUserBelong={doesUserBelong}
        isSurvivorOver={isSurvivorOver}
        userSubgroups={userSubgroups}
        sponsorAbbreviation={sponsorAbbreviation}
      />

      {(userSubgroups?.length > 0 && ((clickState === 'MY_PARTY' && !signUpState) || clickState === 'LEADERBOARD' || clickState === 'PICK_STATS')) ?
      <PartyDropdown 
        userSubgroups={userSubgroups} 
        gameAddress={gameAddress}
        partyState={partyState}
        setPartyState={setPartyState}
        clickState={clickState}
        setPartyId={setPartyId}
      /> 
        : 
      null}
      
      {clickState === 'MY_PICKS' && <MyPicks currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime}/>}

      {clickState === 'HOME' && <SurvivorHome />}

      {/* {clickState === 'RULES' && <NFLSurvivorRules />} */}
      {clickState === 'RULES' && <GameRulesPage gameData={gameData} />}

      {clickState === 'LEADERBOARD' && <SurvivorLeaderboard leaderboardData={leaderboardData} currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime}/>}

      {clickState === 'PICK_STATS' && <SurvivorPickStats leaderboardData={leaderboardData} currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime} />}

      {clickState === 'MY_PARTY' && <MyParty 
          gameData={gameData}
          myUserId={myUserId}
          handleCreateSubgroup={handleCreateSubgroup}
          userSubgroups={userSubgroups} 
          signUpState={signUpState}
          setSignUpState={setSignUpState}
          gameAddress={gameAddress}
          leaderboardData={leaderboardData}
          partyState={partyState}
          partyId={partyId}
          defaultPartyId={defaultPartyId}
          fullLeaderboard={fullLeaderboard}
          doesUserBelong={doesUserBelong}
          joinOpen={joinOpen}
          setJoinOpen={setJoinOpen}
          isGameOver={isSurvivorOver}
      />}

      {clickState === 'PRESENTED_BY' && <SponsorTab gameData={gameData} />}
      

      {joinOpen && (
      <JoinGameModal
          joinOpen={joinOpen}
          setJoinOpen={setJoinOpen}
          gameName={gameName}
          gameAddress={gameAddress}
          gameId={mainGameId}
          gameType={gameType}
          logo={logo}
          myUserId={myUserId}
          setSignUpState={setSignUpState}
          sponsorSecondaryLogo={sponsorSecondaryLogo}
          sponsorName={sponsorName}
      />
    )}

    </div>
    
  );
};

export default NFLSurvivorContainer;
