import React, { useState } from 'react';
import { Box, Typography, Modal, Button } from '@mui/material'; // Import MUI components
import './GameSponsorHeader.scss'; // Import SCSS file
import useNavigateToTop from '../../hooks/general/useNavigateToTop';

const GameSponsorHeader = ({ sponsorLogo, sponsorBannerText, sponsorName, mainGameId, gameAddress, myUserId, setJoinOpen }) => {
  const [sponsorModalOpen, setSponsorModalOpen] = useState(false);

  const navigateToTop = useNavigateToTop();
  const uriEncoded = (link) => {
    return encodeURI(`${encodeURIComponent(link)}`);
  }

 
  const handleClick = () => {
    navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded('PRESENTED_BY')}/${uriEncoded('Highlights')}`);
  }

  return (
    <>
      <Box className="home-page-sponsor-header">
        <img 
          src={sponsorLogo} 
          alt={sponsorName} 
          className="home-page-sponsor-header-image" 
          onClick={handleClick} 
        />
        <Typography 
          variant="h6" 
          className="home-page-sponsor-header-text"
          onClick={handleClick} 
        >
          {sponsorBannerText}
        </Typography>
      </Box>
      {!myUserId &&
      <Box>
      <Button 
        variant="contained" 
        onClick={() => setJoinOpen(true)}
        sx={{ 
            fontSize: '14px', 
            fontWeight: '700', 
            width: '100px', 
            height: '36px', 
            whiteSpace: 'nowrap',
            marginBottom: '10px', 
        }}
      >
        JOIN POOL
      </Button>
      </Box>
      }
    </>
  );
};

export default GameSponsorHeader;
