import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import { useCFBAreGamesInProgress, useCFBCurrentWeek, useCFBGameOddsByWeek, useCFBGamesByWeek, useCFBTeams, useCFBNames } from "../../../hooks/cfb/index";
import { useGetUserData, useGetGameHook, useCreateSubgroup } from "../../BirdiePool/hooks/index";
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { userLogoutAction } from "../../../redux/actions/loginImport";
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import { getUserDataFromLocalStorage } from "../../../redux/actions/loginImport";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import { useGetPoolLeaderboard } from "../../../hooks/pools/useGetPoolLeaderboard";
import { DateTime } from "luxon";

const CFBSurvivorContext = createContext();

export const useCFBSurvivorContext = () => {
  const context = useContext(CFBSurvivorContext);
  if (!context) {
    throw new Error("useCFBPickEmContext must be used within a CFBPickEmProvider");
  }
  return context;
};

const CFBSurvivorProvider = ({ children }) => {
    const myUserId = getUserDataFromLocalStorage()?.user_id;
    //console.log("My User Id", myUserId);
    const { mutate: fetchUserData, data: userData, isLoading: userLoading, isError: userError, error: userErrorData } = useGetUserData();
    const { mutate: fetchGame, data: gameData, isLoading: gameLoading, isError: gameError, error: gameErrorData } = useGetGameHook();
    const { mutate: fetchLeaderboardData, data: leaderboardData, isLoading: leaderboardLoading, isError: leaderboardError, error: leaderboardErrorData } = useGetPoolLeaderboard();
    const { mutate: createSubgroup, data: subgroupData, isLoading: subgroupLoading, isError: subgroupError, error: subgroupErrorData } = useCreateSubgroup();
    const { mutate: fetchAreGamesInProgress, data: areGamesInProgress, isLoading: areGamesInProgressLoading, isError: areGamesInProgressError, error: areGamesInProgressErrorData } = useCFBAreGamesInProgress();
    const { mutate: fetchCurrentWeek, data: currentWeek, isLoading: currentWeekLoading, isError: currentWeekError, error: currentWeekErrorData } = useCFBCurrentWeek();
    const { mutate: fetchCFBGameOddsByWeek, data: CFBGameOddsByWeek, isLoading: CFBGameOddsByWeekLoading, isError: CFBGameOddsByWeekError, error: CFBGameOddsByWeekErrorData } = useCFBGameOddsByWeek();
    const { mutate: fetchCFBGamesByWeek, data: CFBGamesByWeek, isLoading: CFBGamesByWeekLoading, isError: CFBGamesByWeekError, error: CFBGamesByWeekErrorData } = useCFBGamesByWeek();
    const { mutate: fetchCFBTeams, data: CFBTeams, isLoading: CFBTeamsLoading, isError: CFBTeamsError, error: CFBTeamsErrorData } = useCFBTeams();
    const { mutate: fetchCFBNames, data: CFBNames, isLoading: CFBNamesLoading, isError: CFBNamesError, error: CFBNamesErrorData } = useCFBNames();
    const { gameId, component, pageState, optionalState } = useParams();
    const gameType = "Survivor";
    const gameAddress = "cfb-survivor";

    const navigateToTop = useNavigateToTop();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    // Fetch user data first
    useEffect(() => {
        if (myUserId) {
            fetchUserData(myUserId);
        }
    }, [fetchUserData, myUserId]);

    //console.log("userData", userData);

    // Find the game in the user data
    let doesUserBelong = false;
    const thisGame = userData?.Games?.find(game => game.gameID === gameId);
    if (thisGame) {
        doesUserBelong = true;
    }
    //console.log("THIS GAME", thisGame, "Does User Belong", doesUserBelong);
    
    // Get the user's username, their picks, the stop picks warning, and the default party
    const myUsername = userData?.user_name;
    const userPicksFromDB = thisGame?.pool?.picks || [];
    const stopPicksWarning = thisGame?.pool?.stopPicksWarning || false;
    
    // Utility function to check if defaultPartyId is in userSubgroups
    const isDefaultPartyInUserSubgroups = (partyId, subgroups) => {
        return subgroups.some(subgroup => subgroup.subGroupID === partyId);
    };

    let lossWeekACC = null, lossWeekBigTen = null, lossWeekBig12 = null, lossWeekSEC = null;
    let losingGameACC = null, losingGameBigTen = null, losingGameBig12 = null, losingGameSEC = null;
    const doesUserHaveACCLoss = userPicksFromDB?.some(pick => pick.conference === 'ACC' && pick.result === 'Loss');
    const doesUserHaveBigTenLoss = userPicksFromDB?.some(pick => pick.conference === 'Big Ten' && pick.result === 'Loss');
    const doesUserHaveBig12Loss = userPicksFromDB?.some(pick => pick.conference === 'Big 12' && pick.result === 'Loss');
    const doesUserHaveSECLoss = userPicksFromDB?.some(pick => pick.conference === 'SEC' && pick.result === 'Loss');
    
    let defaultConference = "ACC";

    if (doesUserHaveACCLoss) {
        losingGameACC = userPicksFromDB?.find(pick => pick.conference === 'ACC' && pick.result === 'Loss');
        lossWeekACC = losingGameACC?.week;
        defaultConference = "Big 12";
    }

    if (doesUserHaveBig12Loss) {
        losingGameBig12 = userPicksFromDB?.find(pick => pick.conference === 'Big 12' && pick.result === 'Loss');
        lossWeekBig12 = losingGameBig12?.week;
        if (doesUserHaveACCLoss) {
            defaultConference = "Big Ten"; // Changed from "Big 12" to "Big Ten"
        }
    }

    if (doesUserHaveBigTenLoss) {
        losingGameBigTen = userPicksFromDB?.find(pick => pick.conference === 'Big Ten' && pick.result === 'Loss');
        lossWeekBigTen = losingGameBigTen?.week;
        if (doesUserHaveACCLoss) {
            defaultConference = "SEC"; // Changed from "Big Ten" to "SEC"
        }
    }

    if (doesUserHaveSECLoss) {
        losingGameSEC = userPicksFromDB?.find(pick => pick.conference === 'SEC' && pick.result === 'Loss');
        lossWeekSEC = losingGameSEC?.week;
        if (doesUserHaveACCLoss) {
            defaultConference = "ACC"; // Reset to "ACC" if user has ACC loss
        }
    }
    //console.log("Default Conference", defaultConference);

    //console.log("Does User Have ACC Loss", doesUserHaveACCLoss, "Does User Have Big Ten Loss", doesUserHaveBigTenLoss, "Does User Have Big 12 Loss", doesUserHaveBig12Loss, "Does User Have SEC Loss", doesUserHaveSECLoss);
    //console.log("Does Loss Week ACC", lossWeekACC, "Loss Week Big Ten", lossWeekBigTen, "Loss Week Big 12", lossWeekBig12, "Loss Week SEC", lossWeekSEC);

    // Get the default party ID with the additional check
    let defaultPartyIdInDB = Array.isArray(thisGame?.pool?.defaultParty) && thisGame?.pool?.defaultParty?.length > 0
        ? thisGame.pool.defaultParty[0]
        : gameId;

        //console.log("Default Party Id in DB", thisGame?.pool?.defaultParty, defaultPartyIdInDB);
    if (defaultPartyIdInDB !== gameId && !isDefaultPartyInUserSubgroups(defaultPartyIdInDB, thisGame?.subGroups || [])) {
        defaultPartyIdInDB = gameId;
    }
    //console.log("Default Party Id", defaultPartyIdInDB);

    // Get info about user subgroups
    const userSubgroups = thisGame?.subGroups || [];
    //console.log("User Subgroups in Context Party", userSubgroups);
    const firstSubgroupId = userSubgroups[0]?.subGroupID || "";
    const firstSubgroupName = userSubgroups[0]?.name || "";
    // Get the last subgroup if there are any subgroups
    const lastIndex = userSubgroups.length - 1;
    const lastSubgroupId = lastIndex >= 0 ? userSubgroups[lastIndex]?.subGroupID || "" : "";
    const lastSubgroupName = lastIndex >= 0 ? userSubgroups[lastIndex]?.name || "" : "";
    //console.log("First Subgroup ID", firstSubgroupId, "First Subgroup Name", firstSubgroupName, "Last Subgroup ID", lastSubgroupId, "Last Subgroup Name", lastSubgroupName);

    let defaultPartyNameInDB = "Pool Party";
    if (defaultPartyIdInDB !== gameId) {
        const foundSubGroup = userSubgroups.find(subgroup => subgroup.subGroupID === defaultPartyIdInDB);
        defaultPartyNameInDB = foundSubGroup ? foundSubGroup.name : "Pool Party";
    }
    //console.log("Default Party Name", defaultPartyNameInDB);

    //console.log("My Party Default Id", defaultPartyIdInDB, "My Party Default Name", defaultPartyNameInDB);

    // Fetch game data and leaderboard data second
    useEffect(() => {
        if (gameType && gameId) {
            fetchGame({ gameType, gameID: gameId });
            fetchLeaderboardData({ gameID: gameId });
        }
    }, [fetchGame, fetchLeaderboardData, gameId, gameType]);

    // Set loading to false when game and leaderboard data are fetched
    useEffect(() => {
        if (gameData && leaderboardData) {
            setLoading(false);
        }
    }, [gameData, leaderboardData]);

    // Error handling for game fetch
    useEffect(() => {
        if (gameError) {
            console.log("Error fetching game data:", gameErrorData);
            if (gameErrorData.response.status === 401) {
                dispatch(userLogoutAction({
                    logoutType: 'token-expired'
                }));
            } else {
                navigateToTop('/404/pool-hall');
            }
        }

        if (gameData) {
            //console.log("Game Data received:", gameData);
        }
    }, [gameError, gameErrorData, gameData, dispatch, navigateToTop]);

    // Error handling for leaderboard fetching
    useEffect(() => {
        if (leaderboardError) {
            console.error("An error occurred while fetching the leaderboard:", leaderboardErrorData);
            if (leaderboardErrorData.response?.status === 401) {
                dispatch(userLogoutAction({
                    logoutType: 'token-expired'
                }));
            } else {
                navigateToTop('/404/pool-hall');
            }
        }

        if (leaderboardData) {
            //console.log("Leaderboard data received:", leaderboardData);
        }
    }, [leaderboardData, leaderboardError, leaderboardErrorData, dispatch]);

    let startingWeekState = currentWeek ? currentWeek : 1;

    const [pickEmState, setPickEmState] = useState([]);
    const [week, setWeek] = useState(startingWeekState);
    const [unsavedPicksModal, setUnsavedPicksModal] = useState(false);
    const [clickState, setClickState] = useState(null);
    const [signUpState, setSignUpState] = useState(false);
    const [partyState, setPartyState] = useState("");
    const [partyId, setPartyId] = useState(gameId);
    const [defaultPartyId, setDefaultPartyId] = useState(null);
    const [defaultPartyName, setDefaultPartyName] = useState(defaultPartyNameInDB);
    const [teamFilter, setTeamFilter] = useState(null);
    const [rankingsToUse, setRankingsToUse] = useState('ApRank');
    const [isPickEmStateInitialized, setIsPickEmStateInitialized] = useState(false);
    const [teamsToInclude, setTeamsToInclude] = useState([]);
    const [joinOpen, setJoinOpen] = useState(false);

    // Callback to fetch leaderboard data
    const fetchLeaderboardCallback = useCallback(() => {
        if (gameType && gameId) {
            fetchLeaderboardData({ gameID: gameId });
        }
    }, [fetchLeaderboardData, gameId, gameType]);

    // Initialize team filter
    useEffect(() => {
        setTeamFilter(defaultConference);
    }, [defaultConference]);

    // Set initial pickEm state
    useEffect(() => {
        if (userData && gameId) {
            const thisGame = userData?.Games?.find(game => game.gameID === gameId);
            if (thisGame && thisGame.pool?.picks && !isPickEmStateInitialized) {
                setPickEmState(thisGame.pool.picks);
                setIsPickEmStateInitialized(true);
            }
        }
    }, [userData, gameId, isPickEmStateInitialized]);

    useEffect(() => {
        if (defaultPartyIdInDB && defaultPartyNameInDB) {
          setDefaultPartyId(defaultPartyIdInDB);
          setDefaultPartyName(defaultPartyNameInDB);
        }
      }, [defaultPartyIdInDB, defaultPartyNameInDB]);

    // Fetch additional data
    useEffect(() => {
        fetchAreGamesInProgress();
        fetchCurrentWeek();
        fetchCFBGameOddsByWeek();
        fetchCFBGamesByWeek();
        fetchCFBTeams();
        fetchCFBNames();
    }, [fetchAreGamesInProgress, fetchCurrentWeek, fetchCFBGameOddsByWeek, fetchCFBGamesByWeek, fetchCFBTeams, fetchCFBNames]);

    //console.log("areGamesInProgress", areGamesInProgress, "currentWeek", currentWeek, "CFBGameOddsByWeek", CFBGameOddsByWeek, "CFBGamesByWeek", CFBGamesByWeek, "CFBTeams", CFBTeams);

    // Update component state
    useEffect(() => {
        if (userData && gameData && component) {
            if (component === "MY_PICKS") {
                if (optionalState === "default") {
                    setTeamFilter(defaultConference);
                } else if (teamFilter !== optionalState) {
                    setTeamFilter(optionalState);
                }
                if (pageState !== "CURRENT") {
                    const weekNumber = parseInt(pageState, 10);
                    setWeek(weekNumber);
                } else {
                    setWeek(startingWeekState);
                }
                setPartyId(defaultPartyId);
                setPartyState(defaultPartyName);
            }

            if (component === "LEADERBOARD" || component === "TEAM_STATS" || component === "POOL_PICKS") {
                if (pageState !== gameId) {
                    const pageStateInUserSubgroups = userSubgroups?.find(subgroup => subgroup.subGroupID === pageState);
                    if (pageStateInUserSubgroups) {
                        setPartyId(pageState);
                        setPartyState(pageStateInUserSubgroups.name);
                    } else {
                        setPartyId(gameId);
                        setPartyState("Pool Party");
                        navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded(component)}/${uriEncoded(gameId)}`);
                    }
                }
            }

            if (component === "HOME") {
                setPartyId(defaultPartyId);
                setPartyState(defaultPartyName);
            }

            if (component === "MY_PARTY") {
                if (pageState === "MY_PARTY") {
                    setPartyId(defaultPartyId);
                    setPartyState(defaultPartyName);
                    setSignUpState(true);
                } else if (pageState === "NEW") {
                    //console.log("Last Subgroup ID", lastSubgroupId, "Last Subgroup Name", lastSubgroupName);
                    setPartyId(lastSubgroupId);
                    setPartyState(lastSubgroupName);
                    navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded('MY_PARTY')}/${uriEncoded(lastSubgroupId)}`);
                } else {
                    const subgroupName = userSubgroups?.find(subgroup => subgroup.subGroupID === pageState)?.name;
                    setPartyId(pageState);
                    setPartyState(subgroupName || "Unnamed Subgroup");
                }
            }
        }
    }, [userData, gameData, component, pageState, startingWeekState, defaultPartyId, firstSubgroupId, lastSubgroupId]);

    //console.log("userData", userData, "gameData", gameData, "component", component, "pageState", pageState, "startingWeekState", startingWeekState, "defaultPartyId", defaultPartyId, "firstSubgroupId", firstSubgroupId, "lastSubgroupId", lastSubgroupId, "partyId", partyId, "partyState", partyState);

    // Generate week options, from Week 1 through the current week
    const generateWeekOptions = (startingWeek) => {
        const options = [];
        for (let week = startingWeek; week >= 1; week--) {
            options.push(<option key={week} value={`Week ${week}`}>{`Week ${week}`}</option>);
        }
        return options;
    }
    const weekOptions = generateWeekOptions(startingWeekState);

    const lockedBackgroundColor = "#002129"
    const winBackgroundColor = "#00AA72";
    const lossBackgroundColor = "#CC293C";
    const pushBackgroundColor = "rgba(35, 36, 37, 0.75)";
    const winNotSelectedBackgroundColor = "#CCFFEF";
    const pushNotSelectedBackgroundColor = "#BFC7C9";

    const finalizedMyPickBorderColor = "#D6D2D2";
    const finalizedPushBorderColor = "black";
    const finalizedWinBorderColor = "#00AA72";

    const lockedPickFontColor = "#FFF";
    const notSelectedStartedFontColor = "#BDBDBD";
    const notSelectedPushFontColor = "#002129";

    const topLeftLiveColor = "#CC293C";
    const topLeftNotSelectedColor = "#9D9D9D";
    const topLeftSelectedColor = "white";

    const pendingBoxColor = "#232425";
    const winBoxColor = "#00AA72";
    const winBoxBackgroundColor = "rgba(0, 170, 114, 0.20)";
    const lossBoxColor = "#CC293C";
    const lossBoxBackgroundColor = "rgba(204, 41, 60, 0.20)";
    const pushBoxColor = "#5A5B5C";
    const pushBoxBackgroundColor = "rgba(90, 91, 92, 0.20)";

    const handleCreateSubgroup = (subgroupData) => {
        createSubgroup(subgroupData);
    };

    const CFBConferences = [
        {Name: "Atlantic Coast", Abbreviation: "ACC", ConferenceID: 3,},
        {Name: "Big 12", Abbreviation: "Big 12", ConferenceID: 5,},
        {Name: "Big Ten", Abbreviation: "Big Ten", ConferenceID: 6,},
        {Name: "SEC", Abbreviation: "SEC", ConferenceID: 17,},
    ];

    function gatherConferenceTeams(conferenceIDs) {
        // Ensure conferenceIDs is always an array
        if (!Array.isArray(conferenceIDs)) {
            conferenceIDs = [conferenceIDs];
        }
    
        const conferenceTeams = [];
        for (let i = 0; i < CFBTeams?.length; i++) {
            const team = CFBTeams[i];
            if (conferenceIDs.includes(team.ConferenceID)) {
                conferenceTeams.push(team.GlobalTeamID);
            }
        }
        return conferenceTeams;
    }

    useEffect(() => {
        if (teamFilter !== "Top 25" && teamFilter !== "All Games") {
            const conference = CFBConferences?.find(conf => conf.Abbreviation === teamFilter);
            const conferenceIDs = conference?.ConferenceID;
            const conferenceTeams = gatherConferenceTeams(conferenceIDs);
            setTeamsToInclude(conferenceTeams);
        }
    }, [CFBTeams, teamFilter]);

    //console.log("Leaderboard Data", leaderboardData);

    const howManyLeftOnLeaderboard = leaderboardData?.filter(user => user?.isUserEliminated === false).length;
    //console.log("How Many Left On Leaderboard", howManyLeftOnLeaderboard);

    const weekFourteenGames = CFBGamesByWeek?.filter(week => week.Week === 14);
    //console.log("Week Fourteen Games", weekFourteenGames);
    const areAllWeekFourteenGamesFinal = weekFourteenGames?.every(game => game.Status === "Final" || game.Status === "F/OT" || game.Status === "Canceled");
    //console.log("Are All Week Fourteen Games Final", areAllWeekFourteenGamesFinal);

    const isSurvivorOver = (howManyLeftOnLeaderboard === 1 || howManyLeftOnLeaderboard === 0) || areAllWeekFourteenGamesFinal;
    //console.log("Is Survivor Over", isSurvivorOver);

    const leaderboardSortedForFirst = leaderboardData
    ?.sort((a, b) => {
      // First, prioritize isUserEliminated (false should come before true)
      if (a.isUserEliminated !== b.isUserEliminated) {
        return a.isUserEliminated ? 1 : -1;  // false (not eliminated) comes before true (eliminated)
      }

      // Then sort by totalWins (higher comes first)
      if (a.totalWins !== b.totalWins) {
        return b.totalWins - a.totalWins;  // descending order
      }

      // Sort by conferencesRemaining (higher comes first)
      if (a.conferencesRemaining !== b.conferencesRemaining) {
        return b.conferencesRemaining - a.conferencesRemaining;  // descending order
      }
      
      // Finally, sort by totalMargin (higher comes first)
      return b.totalMargin - a.totalMargin;  // descending order
    })[0];  // Get the first result

    //console.log("Leaderboard Sorted for First", leaderboardSortedForFirst);

    const whosInFirst = leaderboardSortedForFirst?.username;
    const whosInFirstCharacterCount = whosInFirst?.length;
    //console.log("Who's In First", whosInFirst, "Character Count", whosInFirstCharacterCount);


    const nowInNewYork = DateTime.now().setZone('America/New_York');

    // Define the date and time in New York timezone
    const deadlineTimeInNewYork = DateTime.fromObject({
        year: 2024,
        month: 8, // September
        day: 31,
        hour: 12, // 1 PM in 24-hour format
        minute: 0,
        second: 0,
        millisecond: 0,
    }, { zone: 'America/New_York' });

    const hasJoinDeadlinePassed = nowInNewYork > deadlineTimeInNewYork;
    //console.log("HAS JOIN DEADLINE PASSED", hasJoinDeadlinePassed);

    const CFBSurvivorContextValues = {
        fetchUserData,
        fetchCFBGameOddsByWeek,
        gameData: gameData,
        userData: userData,
        myUserId,
        myUsername,
        mainGameId: gameId,
        gameType,
        gameAddress,
        doesUserBelong,
        weekOptions,
        CFBTeams,
        CFBNames,
        CFBGamesByWeek,
        CFBGameOddsByWeek,
        doesUserHaveACCLoss,
        losingGameACC,
        lossWeekACC,
        doesUserHaveBigTenLoss,
        losingGameBigTen,
        lossWeekBigTen,
        doesUserHaveBig12Loss,
        losingGameBig12,
        lossWeekBig12,
        doesUserHaveSECLoss,
        losingGameSEC,
        lossWeekSEC,
        joinOpen,
        setJoinOpen,
        teamsToInclude,
        setTeamsToInclude,
        pickEmState,
        setPickEmState,
        startingWeekState,
        week,
        setWeek,
        unsavedPicksModal,
        setUnsavedPicksModal,
        clickState,
        setClickState,
        teamFilter,
        setTeamFilter,
        rankingsToUse,
        setRankingsToUse,
        signUpState,
        setSignUpState,
        partyState,
        setPartyState,
        partyId,
        setPartyId,
        defaultPartyId,
        setDefaultPartyId,
        defaultPartyName,
        setDefaultPartyName,
        firstSubgroupId,
        firstSubgroupName,
        userPicksFromDB,
        stopPicksWarning,
        lockedBackgroundColor,
        winBackgroundColor,
        lossBackgroundColor,
        pushBackgroundColor,
        winNotSelectedBackgroundColor,
        pushNotSelectedBackgroundColor,
        finalizedMyPickBorderColor,
        finalizedPushBorderColor,
        finalizedWinBorderColor,
        lockedPickFontColor,
        notSelectedStartedFontColor,
        notSelectedPushFontColor,
        topLeftLiveColor,
        topLeftNotSelectedColor,
        topLeftSelectedColor,
        pendingBoxColor,
        winBoxColor,
        winBoxBackgroundColor,
        lossBoxColor,
        lossBoxBackgroundColor,
        pushBoxColor,
        pushBoxBackgroundColor,
        handleCreateSubgroup,
        userSubgroups,
        defaultConference,
        leaderboardInfo: leaderboardData,
        leaderboardSortedForFirst,
        whosInFirst,
        whosInFirstCharacterCount,
        isSurvivorOver,
        hasJoinDeadlinePassed,
    };
    //console.log("CFB Pick Em Context Values", CFBSurvivorContextValues);

    // Render loading screen while fetching data
    if (loading || userLoading || gameLoading || leaderboardLoading || areGamesInProgressLoading || currentWeekLoading || CFBGameOddsByWeekLoading || CFBGamesByWeekLoading || CFBTeamsLoading) {
        return <LoadingScreen />;
    }

    return (
        <CFBSurvivorContext.Provider value={CFBSurvivorContextValues}>
            {children}
        </CFBSurvivorContext.Provider>
    );
};

export default CFBSurvivorProvider;
