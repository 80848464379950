import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material'; // Assuming you're using Material UI
import '../BetSlip.scss';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';
import { useSaveSportsbookPicks } from '../../../../hooks/pools/useSaveSportsbookPicks';
import BetSubmissionError from '../../../GameModals/SportsbookModals/BetSubmissionError';
import WarningModal from '../../../GameModals/WarningModal/WarningModal';

const BetSlipSubmitButton = (
  { submitBetActive, 
    pickState, 
    chosenBetStyle, 
    totalPayout, 
    parlayToWin, 
    teaserToWin,
    totalWager,
    parlayWager,
    parlayOdds,
    teaserWager, 
    bankroll,
    selectedTeaserValue,
    teaserPointValue,
    teaserAmericanOdds,
    acceptAllChanges,
    currentGameInterval,
    setBetReceiptInfo,
    setBetSubmitOpen,
    stopPicksWarning,
    warningModalText,
  }) => {
  //console.log("Teaser Points Value: ", teaserPointValue);
  const { mutate, isLoading, isError, error, isSuccess } = useSaveSportsbookPicks();

  const [betSubmitted, setBetSubmitted] = useState(false);
  const [betSubmitError, setBetSubmitError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [warningSubmitted, setWarningSubmitted] = useState(false);

  console.log("Dont Show Again: ", dontShowAgain);
  
  const singleBetsWithWager = pickState?.filter(entry => entry.hasOwnProperty('wager') && Number(entry.wager) > 0);

  const submitPicks = async (data) => {
    try {
      console.log("Trying to submit picks...");
      await mutate(data, {
        onSuccess: async (response) => {
          console.log('Picks submitted successfully:', response);
          setWarningModalOpen(false);
          setBetReceiptInfo(response);
          setBetSubmitOpen(true);
        },
        onError: (error) => {
          console.error('Error submitting picks:', error.response.data.data.message);
          setWarningModalOpen(false);
          setBetSubmitError(true);
          setErrorMessage(error.response.data.data.message);
          
        }
      });
    } catch (error) {
      console.error('Error submitting picks:', error);
      setWarningModalOpen(false);
      setBetSubmitError(true);
      setErrorMessage(error);
    }
  };

  const handleWarningSubmit = () => {
    handleBetSubmit();
    console.log("Submitting Picks after Warning...");
  }

  useEffect(() => {
    if (warningSubmitted) {
      handleBetSubmit();
    }
  }, [warningSubmitted]);


  // Handle the bet submission
  const handleBetSubmit = () => {
    if (betSubmitted) return;

    console.log("Stop Picks Warning: ", stopPicksWarning, "Warning Submitted: ", warningSubmitted);
    if (!stopPicksWarning && !warningSubmitted) {
      setWarningModalOpen(true);
      return;
    }
    console.log("Starting Bet Submission...");
    // console.log('Submit Bet Clicked', pickState, "Chosen Bet Type: ", chosenBetStyle, "Total Payout: ", totalPayout, "Parlay To Win: ", parlayToWin, "Teaser To Win: ", teaserToWin, "Total Wager: ", totalWager, "Parlay Wager: ", parlayWager, "Teaser Wager: ", teaserWager);
    if (chosenBetStyle === "straight") {
      console.log("Inside chosenBetStyle condition");
      const sumOfWagers = pickState?.reduce((acc, pick) => acc + Number(pick.wager).toFixed(2), 0);
      console.log("Sum of Wagers:", sumOfWagers);
    
      if (sumOfWagers > bankroll) {
        console.log("You do not have sufficient funds for these wagers");
      } else {
        console.log("Sufficient funds, proceeding...");
        const betsWithWager = pickState?.filter(entry => entry.hasOwnProperty('wager') && entry.wager > 0);
    
        // Ensure wager and toWin are numbers
        const updatedBetsWithWager = betsWithWager?.map(entry => ({
          ...entry,
          wager: Number(entry.wager),
          //toWin: Number(entry.toWin) // Handle 'toWin' similarly
          toWin: Number(9999)
      }));
      
    
        const sumOfWagers = updatedBetsWithWager.reduce((sum, entry) => sum + entry.wager, 0);
        console.log("Bets With A Wager:", updatedBetsWithWager, "Sum of Wagers: ", sumOfWagers, "Sum of Wagers Less than Bankroll: ", sumOfWagers <= bankroll);
    
        const betInfoToSubmit = {
            betStyle: "straight",
            totalWager: Number(sumOfWagers),
            gameId: "12345",
            gameType: "Sportsbook",
            gameInterval: currentGameInterval,
            acceptAllChanges: acceptAllChanges,
            bets: updatedBetsWithWager,
        };

        if (dontShowAgain) {
          betInfoToSubmit.stopPicksWarning = true;
        }
    
        console.log("Single Bet Info: ", betInfoToSubmit); // Check if this line is reached
        submitPicks(betInfoToSubmit);
        setBetSubmitted(true);
      }
    }
    

    if (chosenBetStyle === "parlay") {
      if (parlayWager > bankroll) {
        console.log("You do not have sufficient funds for this wager");
      } else {
        const updatedPickState = pickState.map(({ wager, toWin, ...rest }) => rest);
        const groupBetId = uuidv4();
        //console.log("Group Bet ID: ", groupBetId);

        
        const betInfoToSubmit = {
          betStyle: "parlay",          
          totalWager: Number(parlayWager),
          gameId: "12345",
          gameType: "Sportsbook",
          acceptAllChanges: acceptAllChanges,
          gameInterval: currentGameInterval,
          groupBetId: groupBetId,
          bets: updatedPickState,
        };

        if (dontShowAgain) {
          betInfoToSubmit.stopPicksWarning = true;
        }
    
        console.log("Parlay Info: ", betInfoToSubmit);
        submitPicks(betInfoToSubmit);
        setBetSubmitted(true);
      }
    }

    if (chosenBetStyle === "teaser") {
      if (teaserWager > bankroll) {
        console.log("You do not have sufficient funds for this wager");
      } else {
        console.log("Pid State: ", pickState);
        const updatedPickState = pickState.map(({ wager, toWin, ...rest }) => rest);
        const groupBetId = uuidv4();

        const sports = updatedPickState.map(pick => pick.sport);
        const uniqueSports = [...new Set(sports)];
        console.log("Unique Sports: ", uniqueSports);

        const teaserInfo = {
          totalWager: Number(teaserWager), // Convert string to number
          overallToWin: Number(teaserToWin), // Convert string to number
          overallBetOdds: teaserAmericanOdds.startsWith('+') 
            ? Number(teaserAmericanOdds.slice(1))  // Remove + and convert to number
            : Number(teaserAmericanOdds),          // Convert to number (already negative or plain number)
          teaserPoints: Number(teaserPointValue),  // Convert string to number
          bets: updatedPickState, // updated pickState without wager and toWin
        };

        const betInfoToSubmit = {
          betStyle: "teaser",
          totalWager: Number(teaserWager),
          groupBetId: groupBetId,
          gameId: "12345",
          gameType: "Sportsbook",
          acceptAllChanges: acceptAllChanges,
          gameInterval: currentGameInterval,
          teaserPoints: Number(teaserPointValue),
          sports: uniqueSports,
          bets: updatedPickState,
        };

        if (dontShowAgain) {
          betInfoToSubmit.stopPicksWarning = true;
        }
        
        console.log("Teaser Info: ", betInfoToSubmit);
        submitPicks(betInfoToSubmit);
        setBetSubmitted(true);
      }
    }
    
  };

  return (
    <>
      <Box className="sportsbook-submit-button-container">
        <Button
          className={`sportsbook-submit-button ${submitBetActive ? 'active' : 'inactive'}`}
          onClick={handleBetSubmit}
          disabled={!submitBetActive || betSubmitted}
        >
        <div className="sportsbook-submit-button-text-top">
          {!submitBetActive && 'To Place Bet'}
          {submitBetActive && chosenBetStyle !== 'straight' && 'Place 1 Bet'}
          {submitBetActive && chosenBetStyle === 'straight' && `Place ${singleBetsWithWager?.length} Bet${singleBetsWithWager?.length > 1 ? 's' : ''}`}
          {chosenBetStyle === 'straight' && totalWager > 0 ? `: ${Number(totalWager).toFixed(2)}` :
          chosenBetStyle === 'parlay' && parlayWager > 0 ? `: ${Number(parlayWager).toFixed(2)}` :
          chosenBetStyle === 'teaser' && teaserWager > 0 ? `: ${Number(teaserWager).toFixed(2)}` : ''}
        </div>

          <div className="sportsbook-submit-button-text-bottom">
            {submitBetActive
              ? `To Win: ${
                  chosenBetStyle === 'straight'
                    ? totalPayout
                    : chosenBetStyle === 'parlay'
                    ? parlayToWin
                    : teaserToWin
                }`
              : 'Enter a Wager'}
          </div>
        </Button>
      </Box>
      <BetSubmissionError 
        betSubmitError={betSubmitError} 
        errorMessage={errorMessage} 
      />
      <WarningModal 
        warningModalOpen={warningModalOpen} 
        setWarningModalOpen={setWarningModalOpen} 
        setDontShowAgain={setDontShowAgain} 
        dontShowAgain={dontShowAgain} 
        handleWarningSubmit={handleWarningSubmit} 
        setWarningSubmitted={setWarningSubmitted}
        warningModalText={warningModalText}
      />
    </>
  );
};

export default BetSlipSubmitButton;
